import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GlobalModalsProvider } from "./GlobalModalsContext";

type Props = {
  children?: React.ReactNode;
};

export const queryClient = new QueryClient();

const Providers = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <GlobalModalsProvider>{children}</GlobalModalsProvider>
    </QueryClientProvider>
  );
};

export default Providers;
