import "bootstrap/dist/css/bootstrap.min.css";
import { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import AcceptedApplicationModal from "./components/AcceptedApplicationModal.jsx";
import FirmLikeCandidateModal from "./components/FirmLikeCandidateModal.jsx";
import MatchModal from "./components/MatchModal.jsx";
import MessageNotificationModal from "./components/MessageNotificationModal.jsx";
import RecruiterMatchModal from "./components/RecruiterMatchModal.jsx";
import CandidateAuthGuard from "./components/routing/CandidateAuthGuard.jsx";
import EmployerAuthGuard from "./components/routing/EmployerAuthGuard.jsx";
import GuestGuard from "./components/routing/GuestGuard.jsx";
import IncompleteCandidateGuard from "./components/routing/IncompleteCandidateGuard.jsx";
import GlobalModalsContext from "./context/GlobalModalsContext";
import useInitAuth from "./hooks/useInitAuth.js";
import AuthenticationApp from "./pages/AuthenticationApp.jsx";
import CandidateComplete from "./pages/CandidateComplete/CandidateComplete.jsx";
import CandidateData0 from "./pages/CandidateData/CandidateData0.jsx";
import CandidateData1 from "./pages/CandidateData/CandidateData1.jsx";
import CandidateData2 from "./pages/CandidateData/CandidateData2.jsx";
import CandidateData4 from "./pages/CandidateData/CandidateData4.jsx";
import CandidateJobDetailsPage from "./pages/CandidateJobDetailsPage/CandidateJobDetailsPage.jsx";
import CandidateMatches from "./pages/CandidateMatches/CandidateMatches.jsx";
import CandidateSignupVerify from "./pages/CandidateSignupVerify/CandidateSignupVerify.jsx";
import CandidateVerify from "./pages/CandidateVerify/CandidateVerify.jsx";
import ChatRoom from "./pages/ChatRoom/ChatRoom.jsx";
import CreateJob from "./pages/CreateJob";
import EmployerSignUpMessage from "./pages/EmployerSignUpMessage/EmployerSignUpMessage.jsx";
import HomeRecruiter from "./pages/HomeRecruiter/HomeRecruiter.jsx";
import JobBoard from "./pages/JobBoard/JobBoard.jsx";
import JobBoardRecruiter from "./pages/JobBoardRecruiter/JobBoardRecruiter.jsx";
import JobPage from "./pages/JobBoardRecruiter/JobPage.jsx";
import JobOverview from "./pages/JobOverview/JobOverview.jsx";
import OurMission from "./pages/LandingPage/OurMission.jsx";
import RecruiterCandidatePagePreview from "./pages/RecruiterCandidatePagePreview/RecruiterCandidatePagePreview.jsx";
import RecruiterCandidatesPage from "./pages/RecruiterCandidatesPage/RecruiterCandidatesPage.jsx";
import RecruiterProfile from "./pages/RecruiterProfile/RecruiterProfile.jsx";
import Register from "./pages/Register/Register.jsx";
import UserProfile from "./pages/UserProfile/UserProfile.jsx";
import "./App.css";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions";
import { useSelector } from "react-redux";
import { isEmployer } from "../src/app/userRedux/userSlice";

const App = () => {
  const navigate = useNavigate();
  const userIsEmployer = useSelector(isEmployer);
  const {
    matchModalData,
    setMatchModalData,
    messageModalData,
    setMessageModalData,
    firmLikeModalData,
    setFirmLikeModalData,
    recruiterMatchModalData,
    setRecruiterMatchModalData,
    acceptedApplicationModalData,
    setAcceptedApplicationModalData,
  } = useContext(GlobalModalsContext);

  const handleCloseMatchModal = () => {
    setMatchModalData(null);
  };

  const handleCloseFirmLikeModal = () => {
    setFirmLikeModalData(null);
  };

  const handleCloseMessageModal = () => {
    setMessageModalData(null);
  };

  const handleCloseRecruiterMatchModal = () => {
    setRecruiterMatchModalData(null);
  };

  const handleCloseAcceptedApplicationModalData = () => {
    setAcceptedApplicationModalData(null);
  };

  const { initialisingAuth } = useInitAuth();

  if (initialisingAuth) {
    return <BarLoader color="#868bff" width="100%" />;
  }

  return (
    <>
      <Routes>
        <Route path="auth/*" element={<AuthenticationApp />} />
        {/* General routes */}
        <Route index element={<Navigate to="/auth/login" />} />
        <Route path="auth" element={<Navigate to="/" />} />
        <Route
          path="/ourmission"
          element={
            <GuestGuard>
              <OurMission />
            </GuestGuard>
          }
        />

        {/* Guest routes */}
        <Route
          path="/candidateSignupVerify"
          element={
            <GuestGuard>
              <CandidateSignupVerify />
            </GuestGuard>
          }
        />
        <Route
          path="/employer-registration-complete"
          element={
            <GuestGuard>
              <EmployerSignUpMessage />
            </GuestGuard>
          }
        />
        <Route
          path="/candidate-verify"
          element={
            <GuestGuard>
              <CandidateVerify />
            </GuestGuard>
          }
        />

        <Route
          path="/register"
          element={
            <GuestGuard>
              <Register />
            </GuestGuard>
          }
        />
        <Route
          path="chat-room"
          element={
            userIsEmployer ? (
              <EmployerAuthGuard>
                <ChatRoom />
              </EmployerAuthGuard>
            ) : (
              <CandidateAuthGuard>
                <ChatRoom />
              </CandidateAuthGuard>
            )
          }
        />
        {/* Candidate routes */}
        <Route
          path="/candidatedata0"
          element={
            <IncompleteCandidateGuard>
              <CandidateData0 />
            </IncompleteCandidateGuard>
          }
        />

        <Route
          path="/candidatedata1"
          element={
            <IncompleteCandidateGuard>
              <CandidateData1 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidatedata2"
          element={
            <IncompleteCandidateGuard>
              <CandidateData2 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidatedata4"
          element={
            <IncompleteCandidateGuard>
              <CandidateData4 />
            </IncompleteCandidateGuard>
          }
        />
        <Route
          path="/candidate-complete"
          element={
            <IncompleteCandidateGuard>
              <CandidateComplete />
            </IncompleteCandidateGuard>
          }
        />

        <Route
          path="/my-matches"
          element={
            <CandidateAuthGuard>
              <CandidateMatches />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/job-board"
          element={
            <CandidateAuthGuard>
              <JobBoard />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/me"
          element={
            <CandidateAuthGuard>
              <UserProfile />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/job-details-page/:jobUid"
          element={
            <CandidateAuthGuard>
              <CandidateJobDetailsPage />
            </CandidateAuthGuard>
          }
        />
        <Route
          path="/me/preview"
          element={
            <CandidateAuthGuard>
              <RecruiterCandidatePagePreview />
            </CandidateAuthGuard>
          }
        />
        {/* Employer routes */}
        <Route
          path="/dashboard"
          element={
            <EmployerAuthGuard>
              <HomeRecruiter />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/my-jobs"
          element={
            <EmployerAuthGuard>
              <JobBoardRecruiter />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/create-job"
          element={
            <EmployerAuthGuard>
              <CreateJob />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/my-job/:id"
          element={
            <EmployerAuthGuard>
              <JobPage />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/job-overview/:id"
          element={
            <EmployerAuthGuard>
              <JobOverview />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/recruiter-profile"
          element={
            <EmployerAuthGuard>
              <RecruiterProfile />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/candidate-board"
          element={
            <EmployerAuthGuard>
              <RecruiterCandidatesPage />
            </EmployerAuthGuard>
          }
        />
        <Route
          path="/candidate-preview/:userUid/:jobUid?"
          element={
            <EmployerAuthGuard>
              <RecruiterCandidatePagePreview />
            </EmployerAuthGuard>
          }
        />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route
          path="*"
          element={
            <div
              style={{
                textAlign: "center",
                padding: "25px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <h1 style={{ color: "white", fontSize: "32px", fontWeight: 700 }}>
                Page not found
              </h1>
              <Button onClick={() => navigate("/")}>
                Go back to the platform
              </Button>
            </div>
          }
        />
      </Routes>
      <MatchModal
        show={matchModalData != null}
        handleClose={handleCloseMatchModal}
        data={matchModalData}
      />
      <AcceptedApplicationModal
        show={acceptedApplicationModalData != null}
        handleClose={handleCloseAcceptedApplicationModalData}
        data={acceptedApplicationModalData}
      />
      <FirmLikeCandidateModal
        show={firmLikeModalData != null}
        handleClose={handleCloseFirmLikeModal}
        data={firmLikeModalData}
      />
      <MessageNotificationModal
        show={messageModalData != null}
        handleClose={handleCloseMessageModal}
      />
      <RecruiterMatchModal
        show={recruiterMatchModalData != null}
        handleClose={handleCloseRecruiterMatchModal}
        data={recruiterMatchModalData}
      />
    </>
  );
};

export default App;
