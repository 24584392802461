import classNames from "classnames";
import { collection, query } from "firebase/firestore";
import { useMemo } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { userSelector } from "../../app/userRedux/userSlice";
import { db } from "../../config/firebase";
import { IMAGES } from "../../constants/images";
import styles from "./Conversation.module.css";
import { messageDateDisplay } from "../../utils/dates";

const stripHtmlTags = (htmlString) => {
  const tempElement = document.createElement("div");
  tempElement.innerHTML = htmlString;
  return tempElement.textContent || tempElement.innerText || "";
};

const removeTrailingNbsp = (message) => {
  while (message.endsWith("&nbsp;")) {
    message = message.slice(0, -6); // Remove the last 6 characters (length of "&nbsp;")
    console.log("Removing trail");
  }
  return message;
};

const getProfileImageSrc = (conversation) => {
  if (!conversation.conversationPartner.isEmployer) {
    if (conversation.incognito) {
      return IMAGES.U_ICON;
    } else {
      return conversation.conversationPartner.profileImg
        ? conversation.conversationPartner.profileImg
        : IMAGES.U_ICON;
    }
  } else {
    return conversation.conversationPartner.firmLogo
      ? conversation.conversationPartner?.firmLogo
      : IMAGES.FIRM_LOGO;
  }
};

const Conversation = ({ conversation, onClick, activeConversationId }) => {
  const user = useSelector(userSelector);
  const conversationPartner = conversation.conversationPartner;

  const [messages, loadingMessages] = useCollectionData(
    query(collection(db, "conversations", conversation.id, "messages"))
  );
  const unreadMessagesCount = messages
    ? messages.filter(
        (message) => !message.read && message.sentBy !== user?.uid
      ).length
    : 0;

  const messagesSorted = messages
    ? messages.sort((a, b) => {
        if (a.date && b.date) {
          return a.date.toDate() - b.date.toDate();
        }
        return 0; // If dates are not available or not in expected format
      })
    : [];

  const lastMessage =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.message
      : null;
  const lastMessageDate =
    messagesSorted.length > 0
      ? messagesSorted[messagesSorted.length - 1]?.date
      : null;

  const sanitisedLastMessage = useMemo(
    () => (lastMessage ? removeTrailingNbsp(stripHtmlTags(lastMessage)) : ""),
    [lastMessage]
  );

  const IMAGE_SRC = getProfileImageSrc(conversation);

  return (
    <div
      className={classNames(styles.container, {
        [styles.isSelected]: activeConversationId === conversation.id,
        [styles.isAnonymous]: conversation.incognito,
        [styles.hasUnreadMessages]: unreadMessagesCount !== 0,
      })}
      onClick={onClick}
    >
      <div className={styles.left}>
        <img
          src={IMAGE_SRC}
          id={conversation.id}
          className={classNames(styles.logo, {
            "object-cover": !conversationPartner?.isEmployer,
            "object-contain": conversationPartner?.isEmployer,
          })}
          alt=""
        />
      </div>
      <div className={`${styles.middle} `}>
        <p className={styles.title}>
          {!conversationPartner.isEmployer
            ? conversation.incognito
              ? conversationPartner.name
              : conversationPartner.surname
                ? conversationPartner.name + " " + conversationPartner.surname
                : conversationPartner.name
            : conversationPartner.companyName}
          <span className={styles.anonymousStatus} />
        </p>
        {lastMessage && (
          <p className={styles.lastMessage}>{sanitisedLastMessage}</p>
        )}
      </div>
      <div className={styles.right}>
        {lastMessageDate && (
          <p className={styles.lastMessageDate}>
            {messageDateDisplay(lastMessageDate.toDate())}
          </p>
        )}
        {unreadMessagesCount !== 0 && (
          <div className={styles.unreadMessagesCount}>
            {unreadMessagesCount}
          </div>
        )}
      </div>
    </div>
  );
};

export default Conversation;
