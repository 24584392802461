import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DotLoader } from "react-spinners";
import RecruiterHomeJobCard, {
  getJobStatusIndicatorColor,
} from "../../components/RecruiterHomeJobCard.jsx";
import { E_JOB_STATUS } from "../../constants/enums";
import { IMAGES } from "../../constants/images.jsx";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import useTotalStatistics from "../../hooks/jobs/useTotalStatistics";
import useFetchConversations from "../../hooks/useFetchConversations.js";
import Conversation from "../ChatRoom/Conversation.jsx";

const HomeRecruiter = () => {
  const { myJobs, isMyJobsLoading } = useMyJobs();
  const { totalStatistics } = useTotalStatistics(
    {
      jobIds: myJobs?.map((job) => job.uid),
    },
    !isMyJobsLoading
  );

  const initialData = useSelector((state) => state.initialData);

  const [totalUnreadCount, setTotalUnreadCount] = useState(0);

  const employer = useSelector((state) => state.data.value);
  const navigate = useNavigate();

  const handleConversationClick = (conversationId) => () => {
    navigate("/chat-room", { state: { conversationId } });
  };

  const { conversations, loadingConversations } = useFetchConversations();

  const groupedConversations = conversations?.reduce((groups, conversation) => {
    const { aboutJob } = conversation; // Extract aboutJob from the conversation

    // Extract the job title from the aboutJob object
    const jobTitle = aboutJob?.jobTitle;

    if (jobTitle) {
      if (!groups[jobTitle]) {
        groups[jobTitle] = [];
      }
      groups[jobTitle].push(conversation);
    } else {
      // Handle cases where jobTitle is not available
      console.error("Job title not found for conversation:", conversation);
    }

    return groups;
  }, {});

  const activeJobsCount = myJobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.active
  ).length;
  const draftJobsCount = myJobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.draft
  ).length;
  const archiveJobsCount = myJobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.archive
  ).length;

  const filledJobsCount = myJobs?.filter(
    (job) => job.jobStatus === E_JOB_STATUS.filled
  ).length;

  return (
    <>
      <div className="flex flex-column  items-center h-lvh text-white overflow-y-auto">
        <div className="flex flex-col-reverse lg:flex-row my-5 mx-3 w-[90%] gap-x-5">
          {/* left */}
          <section className="flex flex-column w-full lg:w-[67%]">
            {/* upper */}
            <div>
              <div className="grid grid-cols-3 gap-2 lg:flex lg:justify-between">
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {myJobs?.length}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Jobs Listed</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalStatistics?.totalSavedCandidates ?? 0}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Liked Candidates</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalStatistics?.totalLikes ?? 0}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Likes me</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalStatistics?.totalMatchedCandidates ?? 0}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Matches</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalStatistics?.totalApplicants ?? 0}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start ">
                    <p>Applicants</p>
                    {/* <p className="text-green-400">+6.3%</p> */}
                  </div>
                </div>

                <div className="p-2 bg-[#1C2E45] w-full items-center justify-center flex flex-col lg:w-[18%] rounded-md">
                  <div>
                    <h1 className="font-bold text-4xl text-yellow-400">
                      {totalUnreadCount}
                    </h1>
                  </div>
                  <div className="flex justify-between mt-2 text-xs text-center lg:text-start">
                    <p>Messages</p>
                    <p className="text-green-400">{""}</p>
                  </div>
                </div>
              </div>
            </div>
            {/* lower */}
            <div>
              <div className="flex-auto bg-[#374B64] rounded-md">
                <div className="flex flex-col md:flex-row justify-between items-center mx-4 my-3 ">
                  <div className="my-3 md:self-start">
                    <p className="text-yellow-400 font-bold">Latest Jobs</p>
                  </div>

                  <div className="grid grid-cols-2 gap-y-1 md:flex items-center gap-x-8 md:self-center">
                    <div className="flex flex-col justify-center items-center">
                      <p
                        className={`flex-2 ${getJobStatusIndicatorColor("active")} w-12 h-2 rounded-md`}
                      />
                      <p className="text-[#4ac27a] text-sm">
                        {activeJobsCount} Active
                      </p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <p
                        className={`flex-2 ${getJobStatusIndicatorColor("draft")} w-12 h-2 rounded-md`}
                      />
                      <p className="text-[#ffc83a] text-sm">
                        {draftJobsCount} Draft
                      </p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <p
                        className={`flex-2 ${getJobStatusIndicatorColor("archive")} w-12 h-2 rounded-md`}
                      />
                      <p className="text-[#ef3b3b] text-sm">
                        {archiveJobsCount} Archived
                      </p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <p
                        className={`flex-2 ${getJobStatusIndicatorColor("filled")} w-12 h-2 rounded-md`}
                      />
                      <p className="text-[#797de5] text-sm">
                        {filledJobsCount} Filled
                      </p>
                    </div>
                  </div>
                  <Button
                    style={{
                      backgroundColor: "#FFC83A",
                    }}
                    onClick={() => navigate("/my-jobs")}
                  >
                    View All
                  </Button>
                </div>
                <div className="mx-4">
                  {/* jobs table head */}
                  <div className="flex items-center text-xs/5 md:text-sm text-gray-400   text-sm text-gray-400">
                    <p className="flex-1">Job Name</p>
                    <p className="flex-1">Date Posted</p>

                    <p className="flex-1">Matches</p>
                    <p className="flex-1">Applicants</p>
                    <p className="flex-1">Messages</p>
                    <p className="flex-2">Status</p>
                  </div>
                  {/* jobs list */}
                  <div className="flex flex-column gap-y-2 text-xs md:text-sm mt-2">
                    {myJobs?.map((job, index) => (
                      <RecruiterHomeJobCard
                        key={job.uid}
                        jobData={job}
                        isLast={index === myJobs.length - 1}
                        setParentTotalUnreadCount={setTotalUnreadCount}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* right */}
          <section className="flex flex-column w-full lg:w-[33%] mb-3">
            {/* upper */}
            <div className="flex w-100 items-center bg-[#374B64] px-2 py-3 rounded-md ">
              <div className="flex-2 mr-5">
                {employer?.firmLogo ? (
                  <>
                    <img
                      src={employer?.firmLogo}
                      alt="firm logo"
                      className="border-2 border-yellow-400 rounded-full w-20 h-20 object-contain"
                    />
                  </>
                ) : (
                  <>
                    <img
                      src={IMAGES.FIRM_LOGO}
                      alt="firm logo"
                      className="border-2 border-yellow-400 rounded-full w-20 h-20 object-contain"
                    />
                  </>
                )}
              </div>
              <div className="flex-1 text-xs w-100">
                <div>
                  <p className="font-bold">{employer?.companyName}</p>
                </div>
                <div className="flex items-center justify-between mt-2 mr-3">
                  <div>
                    <p className="font-bold">Subscription - Pilot</p>
                  </div>
                  <div>
                    <button
                      onClick={() => navigate("/recruiter-profile")}
                      className="bg-[#FFC83A] border-1 border-[#FFC83A] text-black px-3 py-1 rounded-md flex items-center cursor-pointer  hover:text-[#46D6EA]"
                    >
                      Manage
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {/* lower */}
            <div className="flex-auto w-100 mt-3 rounded-md hidden md:block">
              {/* upper part */}
              <div className="bg-[#374B64] mb-3 p-3 rounded-md">
                <div>
                  <h1 className="text-yellow-400 font-bold">Messages</h1>
                </div>
                {loadingConversations ? (
                  <DotLoader color="#ffffff" size={20} />
                ) : (
                  Object.entries(groupedConversations).map(
                    ([job, conversationsInJob]) => (
                      <div key={job}>
                        <h2 className="m-2">{job}</h2>
                        {conversationsInJob.map((conversation) => (
                          <Conversation
                            key={conversation.id}
                            conversation={conversation}
                            onClick={handleConversationClick(conversation.id)}
                          />
                        ))}
                      </div>
                    )
                  )
                )}
              </div>
              {/* lower part */}
            </div>
          </section>
        </div>
      </div>
      {initialData.showUserCreatedModal && (
        <Modal>
          <h1>Welcome</h1>
        </Modal>
      )}
    </>
  );
};

export default HomeRecruiter;
