import * as Sentry from "@sentry/react";

// Replay integration disabled, since we're not using it
export const initSentry = () => {
  Sentry.init({
    dsn: "https://3f41fc6c74d732530124e02ac366d128@o4508097690402816.ingest.de.sentry.io/4508097691975760",
    integrations: [
      Sentry.browserTracingIntegration(),
      //Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      "localhost",
      /^https:\/\/platform\.justlawyers\.app\/api/,
    ],
    // Session Replay
    //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    //replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};
