import { IMAGES } from "../constants/images.jsx";
import { Modal, Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { TABS } from "../pages/CandidateMatches/CandidateMatches";

const FirmLikeCandidateModal = ({ show, handleClose, data }) => {
  const navigate = useNavigate();

  if (data == null) return null;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="flex flex-column items-center justify-center">
        <Row className="items-center justify-center  my-2 w-full">
          <img src={IMAGES.CONE} className=" max-w-fit h-full	" />
        </Row>
        <Row className="items-center justify-center text-center my-2 w-full ">
          <p className="text-4xl font-bold">
            {data.companyName} liked your profile!
          </p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center gap-y-2">
          <Row className="w-75 my-1">
            <Button
              className=" font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent"
              onClick={() => {
                navigate("my-matches", { state: { defaultTab: TABS.Likes } });
                handleClose();
              }}
            >
              Check it out
            </Button>
          </Row>
          <Row className="w-75 my-1">
            <Button
              className=" font-bold border-[#46D6EA] text-black px-5 hover:bg-transparent "
              onClick={handleClose}
            >
              Continue browsing
            </Button>
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default FirmLikeCandidateModal;
