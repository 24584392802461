import { memo, useState } from "react";
import { Button } from "react-bootstrap";
import FiltersModal from "./modals/FiltersModal";
import { FaFilter } from "react-icons/fa";

const SearchBar = memo(({ onClear, onSearch, userData, isFiltered }) => {
  const [isShowModal, setIsShowModal] = useState(false);
  return (
    <div>
      <Button
        style={{
          backgroundColor: userData.isEmployer ? "#FFC83A" : undefined,
        }}
        onClick={() => setIsShowModal(true)}
      >
        Expand search
        {isFiltered && <FaFilter />}
      </Button>
      <div className="flex items-center gap-x-3 justify-end">
        <FiltersModal
          show={isShowModal}
          onSearch={onSearch}
          onClear={onClear}
          onHide={setIsShowModal}
          userData={userData}
        />
      </div>
    </div>
  );
});

export default SearchBar;
