import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { IoMdClose } from "react-icons/io";
import { LuDot } from "react-icons/lu";
import { useSelector } from "react-redux";
import { CANDIDATE_TYPES, JOB_TYPES, NOTIFICATION_TYPES } from ".";
import { db } from "../../config/firebase.js";
import { IMAGES } from "../../constants/images.jsx";
import GlobalModalsContext from "../../context/GlobalModalsContext";
import useCandidate from "../../hooks/candidates/useCandidate";
import useJob from "../../hooks/jobs/useJob";
import NotificationComponent from "./NotificationComponent.jsx";
import styles from "./Notifications.module.css";
import useNotifications from "../../hooks/notifications/useNotifications";
import { queryClient } from "../../context/Providers";
import { useCollectionData } from "react-firebase-hooks/firestore";

const NotificationDropdown = () => {
  const user = useSelector((state) => state.user.userData);
  const [isOpen, setOpen] = useState(false);
  const {
    setMatchModalData,
    setFirmLikeModalData,
    setRecruiterMatchModalData,
    setAcceptedApplicationModalData,
  } = useContext(GlobalModalsContext);

  // const { notifications, isLoading } = useNotifications(user.uid);

  const [notifications] = useCollectionData(
    query(
      collection(db, "notifications"),
      where("userId", "==", user.uid),
      orderBy("date", "desc"),
      limit(5)
    )
  );

  const [currentNotification, setCurrentNotification] = useState(null);

  const { job } = useJob(
    currentNotification?.jobId,
    CANDIDATE_TYPES.includes(currentNotification?.type)
  );
  const { candidate } = useCandidate(
    currentNotification?.reference,
    {},
    JOB_TYPES.includes(currentNotification?.type)
  );

  const updateNotification = async (notification) => {
    const notificationDocument = doc(
      db,
      "notifications",
      notification.notificationId
    );
    await updateDoc(notificationDocument, {
      [`isShowModal_${user.uid}`]: true,
    });
    await queryClient.invalidateQueries("notifications");
  };

  useEffect(() => {
    if (!notifications || notifications.length === 0) return;

    const processNotifications = async () => {
      for (const notification of notifications) {
        if (notification[`isShowModal_${user.uid}`]) continue;

        setCurrentNotification(notification);

        switch (notification.type) {
          case NOTIFICATION_TYPES.LIKE_CANDIDATE:
            if (job) {
              setFirmLikeModalData(job);
              await updateNotification(notification);
            }
            break;
          case NOTIFICATION_TYPES.MATCH_CANDIDATE:
            if (job) {
              setMatchModalData(job);
              await updateNotification(notification);
            }
          case NOTIFICATION_TYPES.MATCH_JOB:
            if (candidate) {
              setRecruiterMatchModalData({
                ...candidate,
                jobId: notification.jobId,
              });
              await updateNotification(notification);
            }
            break;
          case NOTIFICATION_TYPES.ACCEPT_CANDIDATE:
            if (job) {
              setAcceptedApplicationModalData(job);
              await updateNotification(notification);
            }
          default:
            break;
        }
      }
    };

    processNotifications();
  }, [
    notifications,
    job,
    candidate,
    user.uid,
    setFirmLikeModalData,
    setMatchModalData,
    setRecruiterMatchModalData,
    setAcceptedApplicationModalData,
  ]);

  const unreadNotifications = notifications?.filter(
    (notification) => !notification.read
  );

  return (
    <Dropdown show={isOpen} onToggle={(nextShow) => setOpen(nextShow)}>
      <Dropdown.Toggle variant="link" className="after:content-none">
        <img src={IMAGES.BELL} alt="user image" className="w-5 h-6" />
        {unreadNotifications?.length > 0 && (
          <LuDot
            size={42}
            className=" text-[#7B80E9] absolute -top-2 -right-3 "
          />
        )}
      </Dropdown.Toggle>
      <Dropdown.Menu align="end" className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>Notifications</h1>
          <IoMdClose
            size={22}
            className={styles.close}
            onClick={() => setOpen(false)}
          />
        </div>

        {notifications == null || notifications.length === 0 ? (
          <Dropdown.Item disabled={true}>
            <div className={styles.item}>No notifications yet</div>
          </Dropdown.Item>
        ) : (
          notifications?.map((notification, i) => (
            <NotificationComponent notification={notification} key={i} />
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
