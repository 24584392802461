import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { E_JOB_STATUS } from "../constants/enums";
import { userSelector } from "../app/userRedux/userSlice";
import { db } from "../config/firebase";

const getUserDataBasesOnIncognito = (userData, isIncognito) => {
  if (isIncognito) {
    return {
      uid: userData.uid,
      expertise: userData.expertise,
      skills: userData.skills,
      cities: userData.cities,
      name: userData.expertise + " lawyer",
      selectedLanguages: userData.selectedLanguages,
      description: userData.description,
      positiontitle: userData?.positiontitle ?? "",
      employmentHistory:
        userData?.employmentHistory?.map((job) => ({
          employmentend: job.employmentend,
          employmentstart: job.employmentstart,
          positiontitle: job?.positiontitle ?? "",
        })) ?? [],
      university: userData.university,
      grades: userData.grades,
      searchingStatus: userData.searchingStatus,
      experience: userData.experience,
      master: userData.master,
      isIncognito: isIncognito,
      matchedPercentage: userData?.matchedPercentage ?? 0,
      isStudent: userData?.isStudent ?? false,
      dateOfGradutation: userData.dateOfGradutation,
    };
  } else {
    return userData;
  }
};

const isUserAppliedToJob = async (candidateId, jobId) => {
  const userRef = doc(db, "users", candidateId);
  const jobRef = doc(db, "jobs", jobId);
  const applicationsQuery = query(
    collection(db, "applications"),
    where("user_id", "==", userRef),
    where("job_id", "==", jobRef)
  );
  const applicationsSnapshot = await getDocs(applicationsQuery);

  return !applicationsSnapshot.empty;
};

const useFetchConversations = () => {
  const user = useSelector(userSelector);
  const [conversations, setConversations] = useState();

  useEffect(() => {
    const whereStatement = user.isEmployer
      ? where("employer", "==", doc(db, "employers", user.uid))
      : where("candidate", "==", doc(db, "users", user.uid));

    const unsubscribe = onSnapshot(
      query(collection(db, "conversations"), whereStatement),
      async (snapshot) => {
        const conversations = await Promise.all(
          snapshot.docs.map(async (conversationDocument) => {
            const conversationData = conversationDocument.data();
            const messagesCollectionRef = collection(
              conversationDocument.ref,
              "messages"
            );
            const messagesSnapshot = await getDocs(messagesCollectionRef);

            if (
              messagesSnapshot.empty &&
              !conversationData.startedChatByIds.includes(user.uid)
            ) {
              return null;
            }

            const [candidateDoc, jobDoc, conversationPartnerDoc] =
              await Promise.all([
                getDoc(conversationData.candidate),
                getDoc(conversationData.job),
                user.isEmployer
                  ? getDoc(conversationData.candidate)
                  : getDoc(conversationData.employer),
              ]);

            if (
              jobDoc.data() == null ||
              jobDoc.data().jobStatus !== E_JOB_STATUS.active ||
              candidateDoc.data() == null
            ) {
              return null;
            }

            const isProfilePublic = await isUserAppliedToJob(
              candidateDoc.data().uid,
              jobDoc.data().uid
            );

            const aboutJob = jobDoc.exists() ? jobDoc.data() : null;

            const conversationPartner = user.isEmployer
              ? {
                  ...getUserDataBasesOnIncognito(
                    conversationPartnerDoc.data(),
                    isProfilePublic
                      ? !isProfilePublic
                      : conversationData.incognito
                  ),
                }
              : conversationPartnerDoc.data();

            return {
              ...conversationData,
              incognito: isProfilePublic
                ? !isProfilePublic
                : conversationData.incognito,
              aboutJob,
              conversationPartner,
            };
          })
        );

        setConversations(
          conversations.filter((conversation) => conversation !== null)
        );
      }
    );

    return unsubscribe;
  }, [user.uid, user.isEmployer]);

  return {
    conversations,
    loadingConversations: conversations == null,
  };
};

export default useFetchConversations;
