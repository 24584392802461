import classNames from "classnames";
import { isToday, isYesterday } from "date-fns";
import { useState } from "react";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiSuitcaseLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { E_JOB_STATUS } from "../../constants/enums";
import useMyJob from "../../hooks/jobs/useMyJob";
import useStatisticsPerJob from "../../hooks/jobs/useStatisticsPerJob";
import useFetchConversationsForJob from "../../hooks/useFetchConversationsForJob";
import { TABS_JOB_PAGE } from "../../pages/JobBoardRecruiter/JobPage";
import EditJobModal from "../editJobModal/EditJobModal";
import JobCardDropdown from "../JobCardDropdown/JobCardDropdown";
import DeleteJobModal from "../modals/DeleteJobModal";
import styles from "./JobCard.module.css";

const formatDate = (date) => {
  if (isToday(date)) {
    return "Today";
  } else if (isYesterday(date)) {
    return "Yesterday";
  } else {
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

const convertStatusToText = (status) => {
  switch (status) {
    case "active":
      return "Active";
    case "draft":
      return "Draft";
    case "filled":
      return "Filled";
    case "archive":
      return "Archived";
    default:
      return status;
  }
};

const JobCard = ({ handleShowJobPage, jobData, onClick }) => {
  const navigate = useNavigate();
  const { deleteMyJob, updateMyJob } = useMyJob(jobData.uid);
  const { statisticsPerJob } = useStatisticsPerJob(jobData.uid);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteJobModalModal, setShowDeleteJobModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  const { unreadCounts } = useFetchConversationsForJob(jobData.uid);

  const totalUnreadCount = Object.values(unreadCounts).reduce(
    (acc, count) => acc + count,
    0
  );

  const handleStatClick = (stat) => (event) => {
    event.stopPropagation();

    handleShowJobPage(stat);
  };

  const handleChatClick = (event) => {
    event.stopPropagation();

    navigate("/chat-room");
  };

  const handleStatusChange = (status, jobId) => {
    updateMyJob({ uid: jobId, job: { jobStatus: status } });
  };

  return (
    <section
      className={classNames(styles.container, {
        [styles.draft]: jobData.jobStatus === "draft",
        [styles.filled]: jobData.jobStatus === "filled",
        [styles.closed]: jobData.jobStatus === "archive",
      })}
      onClick={onClick}
    >
      <div className={styles.content}>
        <div className={styles.info}>
          <div className={styles.status}>
            {convertStatusToText(jobData.jobStatus)}
          </div>
          <h1 className={styles.title}>{jobData.jobTitle}</h1>
          <div className={styles.postDate}>
            Posted {formatDate(new Date(jobData?.createdAt))}
          </div>
          <div className={styles.statsList}>
            <div className={styles.statsListItem}>
              <CiLocationOn size={20} />
              {jobData?.jobCities?.join(", ")}
            </div>
            <div className={styles.statsListItem}>
              <PiSuitcaseLight size={20} />
              {jobData.isStudent ? (
                <>Internship or student job</>
              ) : (
                <>
                  {jobData.jobExperienceMin} - {jobData.jobExperienceMax} years
                  of experience
                </>
              )}
            </div>
            <div className={styles.statsListItem}>
              <MdOutlineMenu size={20} />
              {jobData?.jobExpertise?.join(", ")}
            </div>
            <div className={styles.statsListItem}>
              <BiMessage size={20} />
              {jobData?.jobLanguages?.join(", ")}
            </div>
          </div>
        </div>
        <div className={styles.stats}>
          <div
            className={styles.stat}
            onClick={handleStatClick(TABS_JOB_PAGE.Potential)}
          >
            <div className={styles.statNumber}>
              {statisticsPerJob?.amountPotentialCandidates ?? 0}
            </div>
            <div className={styles.statTitle}>Potential Candidates</div>
          </div>
          <div
            className={styles.stat}
            onClick={handleStatClick(TABS_JOB_PAGE.Liked)}
          >
            <div className={styles.statNumber}>
              {statisticsPerJob?.amountSavedCandidates ?? 0}
            </div>
            <div className={styles.statTitle}>Liked Candidates</div>
          </div>
          <div
            className={styles.stat}
            onClick={handleStatClick(TABS_JOB_PAGE.Likes)}
          >
            <div className={styles.statNumber}>
              {statisticsPerJob?.amountCandidateLikes ?? 0}
            </div>
            <div className={styles.statTitle}>Likes Job</div>
          </div>
          <div
            className={styles.stat}
            onClick={handleStatClick(TABS_JOB_PAGE.Matches)}
          >
            <div className={styles.statNumber}>
              {statisticsPerJob?.amountMatchedCandidates ?? 0}
            </div>
            <div className={styles.statTitle}>Matches</div>
          </div>
          <div
            className={styles.stat}
            onClick={handleStatClick(TABS_JOB_PAGE.Applicants)}
          >
            <div className={styles.statNumber}>
              {statisticsPerJob?.amountApplicants ?? 0}
            </div>
            <div className={styles.statTitle}>Applicants</div>
          </div>
          <div className={styles.stat} onClick={handleChatClick}>
            <div className={styles.statNumber}>{totalUnreadCount}</div>
            <div className={styles.statTitle}>Messages</div>
          </div>
        </div>
      </div>
      <div className={styles.menu} onClick={(event) => event.stopPropagation()}>
        <JobCardDropdown
          handleEdit={handleShow}
          handleDelete={() => setShowDeleteJobModal(true)}
          handleStatusChange={(status) =>
            handleStatusChange(status, jobData.uid)
          }
          isActive={jobData.jobStatus === E_JOB_STATUS.active}
          isArchived={jobData.jobStatus === E_JOB_STATUS.archive}
          isFilled={jobData.jobStatus === E_JOB_STATUS.filled}
        />
        <EditJobModal
          showModal={showModal}
          handleClose={handleClose}
          job={jobData}
        />
        <DeleteJobModal
          show={showDeleteJobModalModal}
          onHide={() => setShowDeleteJobModal(false)}
          onDelete={() => deleteMyJob(jobData.uid)}
        />
      </div>
    </section>
  );
};

export default JobCard;
