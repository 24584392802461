// @ts-ignore
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const TermsAndConditions = () => {
  const navigate = useNavigate();
  return (
    <div
      style={{
        margin: "20px auto",
        width: "80%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "end",
      }}
    >
      <Button onClick={() => navigate("/")}>Go to the platform</Button>
      <div
        style={{
          margin: "20px auto",

          backgroundColor: "#FFF",
          height: "100%",
          minHeight: "75vh",
          color: "#000",
          borderRadius: "8px",
          border: "1px solid #E5E5E5",
          padding: "20px 60px",
          display: "flex",
          justifyContent: "start",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <h1
          style={{
            fontSize: "2rem",
            fontWeight: 700,
            color: "#19293D",
            marginBottom: "1rem",
          }}
        >
          Terms and Conditions
        </h1>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "start",
            gap: "1rem",
            marginBottom: "1rem",
            fontWeight: 500,
          }}
        >
          <p>
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            convallis eu ipsum finibus lacinia. In at consectetur nunc. Sed sed
            nulla lacus. Praesent ut elementum turpis, quis lacinia libero.
            Phasellus vel sapien eget arcu sollicitudin porttitor. In volutpat,
            orci sit amet luctus scelerisque, lorem lorem egestas purus, non
            varius ante elit nec orci. Ut at tortor at lorem egestas mattis nec
            eget elit. Quisque congue sed sem nec faucibus. Quisque sed elit
            urna. Donec vitae gravida lectus. Pellentesque elementum ac eros
            quis iaculis. In consequat nunc dolor, quis sagittis dui volutpat
            in. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices
            posuere cubilia curae; Pellentesque et ante vitae nisi bibendum
            venenatis at at nulla. Cras porta lorem vel metus accumsan,
            imperdiet cursus justo fringilla. Donec in finibus nunc, nec egestas
            lacus. Nulla facilisi. Nullam enim felis, aliquam ut neque non,
            interdum vestibulum ligula. Vestibulum elementum elementum dictum.
            Vestibulum tristique fringilla est, vel pretium sem volutpat et.
            Praesent metus ligula, aliquam vitae ligula vel, tincidunt lobortis
            ligula. Interdum et malesuada fames ac ante ipsum primis in
            faucibus. Mauris et faucibus velit. Pellentesque aliquam elementum
            consequat. Cras tincidunt metus vitae blandit placerat. Mauris
            accumsan, nunc a ornare aliquam, elit dolor vestibulum tortor, sit
            amet ornare nisl odio eget mi. Morbi eget vestibulum urna, at varius
            lectus. Nullam id arcu magna. Aliquam tempor, quam id ultricies
            porttitor, magna neque imperdiet quam, ultricies mollis diam lectus
            ultricies lorem. Etiam dolor lacus, scelerisque ut nunc ut, mattis
            euismod metus. Ut ultrices non dui vel porta. Aliquam a nisi at
            velit suscipit ultricies. Duis aliquet dolor at nibh efficitur
            pellentesque. Quisque auctor erat gravida aliquam convallis. Quisque
            egestas, dolor sit amet pharetra ullamcorper, massa neque interdum
            magna, malesuada cursus mauris eros ac lacus. Ut sollicitudin
            vestibulum rutrum. Vestibulum ante ipsum primis in faucibus orci
            luctus et ultrices posuere cubilia curae; Nunc ultricies orci et
            enim fermentum accumsan. Nullam lacinia, risus eu tincidunt auctor,
            risus risus euismod est, vitae pellentesque metus nisi eleifend
            orci. Donec dolor libero, eleifend ac tempus sit amet, tincidunt id
            diam. Phasellus facilisis lacinia odio ut tempor. Nunc sit amet
            massa in tellus vehicula rutrum id non nisi. Suspendisse gravida
            maximus augue non vehicula. In aliquam eros ante, sit amet ultricies
            nisi imperdiet non. Mauris finibus nulla eget ipsum semper suscipit.
            Maecenas eget pretium turpis. Nulla arcu sem, laoreet quis justo
            porttitor, facilisis commodo lacus. Aliquam id elementum purus. Duis
            imperdiet eleifend nibh non fermentum. In id velit nunc. Nam quis
            posuere lectus, id dictum nisi. Aenean non elit sed orci dictum
            sollicitudin. Integer finibus tempor eros, sit amet sollicitudin
            sapien volutpat vel. Morbi pellentesque nunc rutrum ante scelerisque
            sollicitudin. Vivamus gravida, augue id mollis ornare, massa ex
            euismod leo, at hendrerit quam neque vulputate augue. Fusce ut lacus
            id purus dictum pulvinar. Sed dictum neque id diam rhoncus placerat.
            Nulla velit tellus, lobortis sed nulla vel, accumsan dignissim
            massa. Praesent at condimentum diam, et lacinia odio. Fusce sem
            elit, posuere in lacinia eu, pharetra in nulla. Aenean eu sem eu
            risus accumsan laoreet pharetra rutrum ligula. Nulla suscipit ante
            ligula, a ultricies tortor vehicula nec. Cras aliquam, arcu nec
            accumsan accum
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
