import { IMAGES } from "../constants/images.jsx";
import { Modal, Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import useNavigateToConversation from "../hooks/useNavigateToConversation.js";

const MatchModal = ({ show, handleClose, data }) => {
  const { navigateToConversation } = useNavigateToConversation();
  const initialData = useSelector((state) => state.initialData);

  if (data == null) return null;

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="flex flex-column items-center justify-center">
        <Row className="items-center justify-between border-2 border-[#46D6EA] rounded-full my-2 w-[45%]">
          <div className="flex items-center justify-center w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full overflow-hidden p-0">
            <img
              src={initialData?.profileImg ?? IMAGES.U_ICON}
              className="w-full h-full object-cover rounded-full object-cover"
            />
          </div>
          <div className="flex items-center justify-center w-14 h-14 md:w-20 md:h-20 border-2 border-[#46D6EA] rounded-full overflow-hidden p-0">
            <img
              src={data?.firmLogo ?? IMAGES.FIRM_LOGO}
              className="w-full h-full object-cover rounded-full object-contain"
            />
          </div>
        </Row>
        <Row className="items-center justify-center text-center my-2 w-full ">
          <p className="text-4xl font-bold">You have a match!</p>
          <p className="">You just matched with {data?.companyName}!</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center gap-y-2">
          <Row className="w-75 my-1">
            <Button
              className=" font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent"
              onClick={() => {
                navigateToConversation(data.userid, data.uid);
                handleClose();
              }}
            >
              Get in touch first
            </Button>
          </Row>
          <Row className="w-75 my-1">
            <Button
              className=" font-bold border-[#46D6EA] text-black px-5 hover:bg-transparent "
              onClick={handleClose}
            >
              Continue browsing
            </Button>
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default MatchModal;
