import { Button, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { IMAGES } from "../constants/images.jsx";

const MessageNotificationModal = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const data = useSelector((state) => state.data.value);
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Body className="flex flex-column items-center justify-center">
        <Row className="items-center justify-center  my-2 w-full">
          {/* <div className="flex items-center justify-center w-20 h-20 border-2 border-[#46D6EA] rounded-full"> */}
          <img src={IMAGES.ENVELOPE} className=" max-w-fit h-full	" />
          {/* </div> */}
          {/* <div className="flex items-center justify-center w-20 h-20 border-2 border-[#46D6EA] rounded-full">
            <img
              src={IMAGES.REC_LOGO}
              className="rounded-full max-w-fit h-full	"
            />
          </div> */}
        </Row>
        <Row className="items-center justify-center text-center my-2 w-full ">
          <p className="text-4xl font-bold">You have a new message!</p>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Row className="flex items-center justify-center gap-y-2">
          <Row className="w-75 my-1">
            <Button
              className=" font-bold bg-[#46D6EA] text-black px-5 hover:bg-transparent"
              //   variant="info"

              onClick={() => {
                navigate(`/chat-room/${data.uid}`);
                handleClose();
              }}
            >
              Check it out
            </Button>
          </Row>
          <Row className="w-75 my-1">
            <Button
              className=" font-bold border-[#46D6EA] text-black px-5 hover:bg-transparent "
              //   variant="info"

              onClick={handleClose}
            >
              Continue browsing
            </Button>
          </Row>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageNotificationModal;
