import universities from "./world_universities.json";

export const unisList = universities;

export const jobPositions = [
  "Summer intern",
  "Bar Traineeship",
  "Junior Associate",
  "Mid-level",
  "Senior Associate",
];

export const jobExperience = ["0", "1-3", "4-5", "5+"];

export const languagesOptions = [
  {
    value: "English",
    label: "English",
  },
  {
    value: "Spanish",
    label: "Spanish",
  },
  {
    value: "French",
    label: "French",
  },
  {
    value: "German",
    label: "German",
  },
  {
    value: "Chinese",
    label: "Chinese",
  },
  {
    value: "Japanese",
    label: "Japanese",
  },
  {
    value: "Arabic",
    label: "Arabic",
  },
  {
    value: "Russian",
    label: "Russian",
  },
  {
    value: "Italian",
    label: "Italian",
  },
  {
    value: "Portuguese",
    label: "Portuguese",
  },
  {
    value: "Dutch",
    label: "Dutch",
  },
  {
    value: "Korean",
    label: "Korean",
  },
  {
    value: "Turkish",
    label: "Turkish",
  },
  {
    value: "Swedish",
    label: "Swedish",
  },
  {
    value: "Polish",
    label: "Polish",
  },
  {
    value: "Indonesian",
    label: "Indonesian",
  },
  {
    value: "Greek",
    label: "Greek",
  },
  {
    value: "Hindi",
    label: "Hindi",
  },
  {
    value: "Bengali",
    label: "Bengali",
  },
  {
    value: "Vietnamese",
    label: "Vietnamese",
  },
  {
    value: "Thai",
    label: "Thai",
  },
  {
    value: "Hebrew",
    label: "Hebrew",
  },
  {
    value: "Finnish",
    label: "Finnish",
  },
  {
    value: "Norwegian",
    label: "Norwegian",
  },
  {
    value: "Danish",
    label: "Danish",
  },
  {
    value: "Czech",
    label: "Czech",
  },
  {
    value: "Slovak",
    label: "Slovak",
  },
  {
    value: "Hungarian",
    label: "Hungarian",
  },
  {
    value: "Romanian",
    label: "Romanian",
  },
  {
    value: "Bulgarian",
    label: "Bulgarian",
  },
  {
    value: "Croatian",
    label: "Croatian",
  },
  {
    value: "Serbian",
    label: "Serbian",
  },
  {
    value: "Slovenian",
    label: "Slovenian",
  },
  {
    value: "Macedonian",
    label: "Macedonian",
  },
  {
    value: "Albanian",
    label: "Albanian",
  },
  {
    value: "Bosnian",
    label: "Bosnian",
  },
  {
    value: "Montenegrin",
    label: "Montenegrin",
  },
  {
    value: "Kurdish",
    label: "Kurdish",
  },
  {
    value: "Persian",
    label: "Persian",
  },
  {
    value: "Urdu",
    label: "Urdu",
  },
  {
    value: "Pashto",
    label: "Pashto",
  },
  {
    value: "Sindhi",
    label: "Sindhi",
  },
  {
    value: "Saraiki",
    label: "Saraiki",
  },
  {
    value: "Punjabi",
    label: "Punjabi",
  },
  {
    value: "Gujarati",
    label: "Gujarati",
  },
  {
    value: "Marathi",
    label: "Marathi",
  },
  {
    value: "Telugu",
    label: "Telugu",
  },
  {
    value: "Tamil",
    label: "Tamil",
  },
  {
    value: "Malayalam",
    label: "Malayalam",
  },
  {
    value: "Kannada",
    label: "Kannada",
  },
  {
    value: "Odia",
    label: "Odia",
  },
  {
    value: "Burmese",
    label: "Burmese",
  },
  {
    value: "Khmer",
    label: "Khmer",
  },
  {
    value: "Lao",
    label: "Lao",
  },
  {
    value: "Malay",
    label: "Malay",
  },
  {
    value: "Tagalog",
    label: "Tagalog",
  },
  {
    value: "Bisaya",
    label: "Bisaya",
  },
  {
    value: "Hiligaynon",
    label: "Hiligaynon",
  },
  {
    value: "Waray",
    label: "Waray",
  },
  {
    value: "Chavacano",
    label: "Chavacano",
  },
  {
    value: "Javanese",
    label: "Javanese",
  },
  {
    value: "Sundanese",
    label: "Sundanese",
  },
  {
    value: "Madurese",
    label: "Madurese",
  },
  {
    value: "Minangkabau",
    label: "Minangkabau",
  },
  {
    value: "Malagasy",
    label: "Malagasy",
  },
  {
    value: "Maori",
    label: "Maori",
  },
  {
    value: "Samoan",
    label: "Samoan",
  },
  {
    value: "Tongan",
    label: "Tongan",
  },
  {
    value: "Fijian",
    label: "Fijian",
  },
  {
    value: "Hawaiian",
    label: "Hawaiian",
  },
  {
    value: "Marshallese",
    label: "Marshallese",
  },
  {
    value: "Chamorro",
    label: "Chamorro",
  },
  {
    value: "Palauan",
    label: "Palauan",
  },
  {
    value: "Kosraean",
    label: "Kosraean",
  },
  {
    value: "Pohnpeian",
    label: "Pohnpeian",
  },
  {
    value: "Chuukese",
    label: "Chuukese",
  },
  {
    value: "Yapese",
    label: "Yapese",
  },
  {
    value: "Nauruan",
    label: "Nauruan",
  },
  {
    value: "Kiribati",
    label: "Kiribati",
  },
  {
    value: "Tuvaluan",
    label: "Tuvaluan",
  },
  {
    value: "Tokelauan",
    label: "Tokelauan",
  },
  {
    value: "Wallisian",
    label: "Wallisian",
  },
  {
    value: "Futunan",
    label: "Futunan",
  },
  {
    value: "Niuean",
    label: "Niuean",
  },
  {
    value: "Cook Islands Maori",
    label: "Cook Islands Maori",
  },
  {
    value: "Tahitian",
    label: "Tahitian",
  },
  {
    value: "Rapa Nui",
    label: "Rapa Nui",
  },
  {
    value: "Rarotongan",
    label: "Rarotongan",
  },
  {
    value: "Pitcairnese",
    label: "Pitcairnese",
  },
  {
    value: "Rotuman",
    label: "Rotuman",
  },
  {
    value: "Tok Pisin",
    label: "Tok Pisin",
  },
  {
    value: "Hiri Motu",
    label: "Hiri Motu",
  },
  {
    value: "Solomon Islands Pijin",
    label: "Solomon Islands Pijin",
  },
  {
    value: "Bislama",
    label: "Bislama",
  },
  {
    value: "Fijian Hindustani",
    label: "Fijian Hindustani",
  },
  {
    value: "Fijian English",
    label: "Fijian English",
  },
  {
    value: "Solomon Islands Pijin English",
    label: "Solomon Islands Pijin English",
  },
];

export const citiesOptions = [
  { value: "Abidjan", label: "Abidjan" },
  { value: "Abu Dhabi", label: "Abu Dhabi" },
  { value: "Abuja", label: "Abuja" },
  { value: "Acapulco", label: "Acapulco" },
  { value: "Accra", label: "Accra" },
  { value: "Addis Ababa", label: "Addis Ababa" },
  { value: "Adelaide", label: "Adelaide" },
  { value: "Agra", label: "Agra" },
  { value: "Ahmedabad", label: "Ahmedabad" },
  { value: "Albuquerque", label: "Albuquerque" },
  { value: "Alexandria", label: "Alexandria" },
  { value: "Algiers", label: "Algiers" },
  { value: "Alicante", label: "Alicante" },
  { value: "Almaty", label: "Almaty" },
  { value: "Amman", label: "Amman" },
  { value: "Amsterdam", label: "Amsterdam" },
  { value: "Anchorage", label: "Anchorage" },
  { value: "Andorra la Vella", label: "Andorra la Vella" },
  { value: "Antwerp", label: "Antwerp" },
  { value: "Ghent", label: "Ghent" },
  { value: "Bruges", label: "Bruges" },
  { value: "Liege", label: "Liege" },
  { value: "Leuven", label: "Leuven" },
  { value: "Namur", label: "Namur" },
  { value: "Mechelen", label: "Mechelen" },
  { value: "Hasselt", label: "Hasselt" },
  { value: "Kortrijk", label: "Kortrijk" },
  { value: "Ankara", label: "Ankara" },
  { value: "Antalya", label: "Antalya" },
  { value: "Antananarivo", label: "Antananarivo" },
  { value: "Arusha", label: "Arusha" },
  { value: "Asheville", label: "Asheville" },
  { value: "Ashgabat", label: "Ashgabat" },
  { value: "Asmara", label: "Asmara" },
  { value: "Astana", label: "Astana" },
  { value: "Asunción", label: "Asunción" },
  { value: "Athens", label: "Athens" },
  { value: "Atlanta", label: "Atlanta" },
  { value: "Auckland", label: "Auckland" },
  { value: "Austin", label: "Austin" },
  { value: "Avalon (California)", label: "Avalon (California)" },
  { value: "Baghdad", label: "Baghdad" },
  { value: "Baku", label: "Baku" },
  { value: "Bali", label: "Bali" },
  { value: "Baltimore", label: "Baltimore" },
  { value: "Bamako", label: "Bamako" },
  { value: "Bangalore", label: "Bangalore" },
  { value: "Bangkok", label: "Bangkok" },
  { value: "Bangui", label: "Bangui" },
  { value: "Banjul", label: "Banjul" },
  { value: "Barcelona", label: "Barcelona" },
  { value: "Barranquilla", label: "Barranquilla" },
  { value: "Beijing", label: "Beijing" },
  { value: "Beirut", label: "Beirut" },
  { value: "Belfast", label: "Belfast" },
  { value: "Belgrade", label: "Belgrade" },
  { value: "Belize City", label: "Belize City" },
  { value: "Belo Horizonte", label: "Belo Horizonte" },
  { value: "Bengaluru", label: "Bengaluru" },
  { value: "Bergen", label: "Bergen" },
  { value: "Berlin", label: "Berlin" },
  { value: "Bern", label: "Bern" },
  { value: "Bilbao", label: "Bilbao" },
  { value: "Birmingham", label: "Birmingham" },
  { value: "Bishkek", label: "Bishkek" },
  { value: "Bissau", label: "Bissau" },
  { value: "Blantyre", label: "Blantyre" },
  { value: "Bogotá", label: "Bogotá" },
  { value: "Bologna", label: "Bologna" },
  { value: "Bora Bora", label: "Bora Bora" },
  { value: "Bordeaux", label: "Bordeaux" },
  { value: "Boston", label: "Boston" },
  { value: "Brasília", label: "Brasília" },
  { value: "Bratislava", label: "Bratislava" },
  { value: "Brazzaville", label: "Brazzaville" },
  { value: "Brisbane", label: "Brisbane" },
  { value: "Brussels", label: "Brussels" },
  { value: "Bucharest", label: "Bucharest" },
  { value: "Budapest", label: "Budapest" },
  { value: "Buenos Aires", label: "Buenos Aires" },
  { value: "Buffalo", label: "Buffalo" },
  { value: "Bujumbura", label: "Bujumbura" },
  { value: "Bulawayo", label: "Bulawayo" },
  { value: "Bursa", label: "Bursa" },
  { value: "Busan", label: "Busan" },
  { value: "Cabo San Lucas", label: "Cabo San Lucas" },
  { value: "Cairo", label: "Cairo" },
  { value: "Calgary", label: "Calgary" },
  { value: "Cali", label: "Cali" },
  { value: "Canberra", label: "Canberra" },
  { value: "Cancún", label: "Cancún" },
  { value: "Cape Town", label: "Cape Town" },
  { value: "Caracas", label: "Caracas" },
  { value: "Cardiff", label: "Cardiff" },
  { value: "Casablanca", label: "Casablanca" },
  { value: "Cebu City", label: "Cebu City" },
  { value: "Chandigarh", label: "Chandigarh" },
  { value: "Chania", label: "Chania" },
  { value: "Charlotte", label: "Charlotte" },
  { value: "Chelyabinsk", label: "Chelyabinsk" },
  { value: "Chennai", label: "Chennai" },
  { value: "Cheyenne", label: "Cheyenne" },
  { value: "Chicago", label: "Chicago" },
  { value: "Chihuahua City", label: "Chihuahua City" },
  { value: "Chisinau", label: "Chisinau" },
  { value: "Christchurch", label: "Christchurch" },
  { value: "Cincinnati", label: "Cincinnati" },
  { value: "Cleveland", label: "Cleveland" },
  { value: "Cluj-Napoca", label: "Cluj-Napoca" },
  { value: "Cochabamba", label: "Cochabamba" },
  { value: "Cologne", label: "Cologne" },
  { value: "Colombo", label: "Colombo" },
  { value: "Colorado Springs", label: "Colorado Springs" },
  { value: "Columbia", label: "Columbia" },
  { value: "Columbus", label: "Columbus" },
  { value: "Conakry", label: "Conakry" },
  { value: "Copenhagen", label: "Copenhagen" },
  { value: "Cordoba", label: "Cordoba" },
  { value: "Cotonou", label: "Cotonou" },
  { value: "Cozumel", label: "Cozumel" },
  { value: "Curitiba", label: "Curitiba" },
  { value: "Da Nang", label: "Da Nang" },
  { value: "Dakar", label: "Dakar" },
  { value: "Dallas", label: "Dallas" },
  { value: "Dar es Salaam", label: "Dar es Salaam" },
  { value: "Darwin", label: "Darwin" },
  { value: "Davao City", label: "Davao City" },
  { value: "Delhi", label: "Delhi" },
  { value: "Denpasar", label: "Denpasar" },
  { value: "Denver", label: "Denver" },
  { value: "Detroit", label: "Detroit" },
  { value: "Dhaka", label: "Dhaka" },
  { value: "Doha", label: "Doha" },
  { value: "Dortmund", label: "Dortmund" },
  { value: "Dresden", label: "Dresden" },
  { value: "Dubai", label: "Dubai" },
  { value: "Dublin", label: "Dublin" },
  { value: "Durban", label: "Durban" },
  { value: "Dushanbe", label: "Dushanbe" },
  { value: "Edinburgh", label: "Edinburgh" },
  { value: "Edmonton", label: "Edmonton" },
  { value: "Eilat", label: "Eilat" },
  { value: "Ekibastuz", label: "Ekibastuz" },
  { value: "El Paso", label: "El Paso" },
  { value: "Erzurum", label: "Erzurum" },
  { value: "Esbjerg", label: "Esbjerg" },
  { value: "Esfahan", label: "Esfahan" },
  { value: "Eugene", label: "Eugene" },
  { value: "Evansville", label: "Evansville" },
  { value: "Exeter", label: "Exeter" },
  { value: "Faisalabad", label: "Faisalabad" },
  { value: "Faro", label: "Faro" },
  { value: "Fez", label: "Fez" },
  { value: "Florence", label: "Florence" },
  { value: "Fortaleza", label: "Fortaleza" },
  { value: "Frankfurt", label: "Frankfurt" },
  { value: "Freetown", label: "Freetown" },
  { value: "Fresno", label: "Fresno" },
  { value: "Fukuoka", label: "Fukuoka" },
  { value: "Funafuti", label: "Funafuti" },
  { value: "Gaborone", label: "Gaborone" },
  { value: "Galway", label: "Galway" },
  { value: "Gaza City", label: "Gaza City" },
  { value: "Geneva", label: "Geneva" },
  { value: "Genoa", label: "Genoa" },
  { value: "George Town", label: "George Town" },
  { value: "Gibraltar", label: "Gibraltar" },
  { value: "Giza", label: "Giza" },
  { value: "Glasgow", label: "Glasgow" },
  { value: "Gold Coast", label: "Gold Coast" },
  { value: "Gothenburg", label: "Gothenburg" },
  { value: "Granada", label: "Granada" },
  { value: "Grand Rapids", label: "Grand Rapids" },
  { value: "Green Bay", label: "Green Bay" },
  { value: "Greensboro", label: "Greensboro" },
  { value: "Grenoble", label: "Grenoble" },
  { value: "Guadalajara", label: "Guadalajara" },
  { value: "Guangzhou", label: "Guangzhou" },
  { value: "Guatemala City", label: "Guatemala City" },
  { value: "Guayaquil", label: "Guayaquil" },
  { value: "Gujranwala", label: "Gujranwala" },
  { value: "Gwangju", label: "Gwangju" },
  { value: "Hague", label: "Hague" },
  { value: "Hamburg", label: "Hamburg" },
  { value: "Hamilton", label: "Hamilton" },
  { value: "Hanoi", label: "Hanoi" },
  { value: "Harare", label: "Harare" },
  { value: "Hargeisa", label: "Hargeisa" },
  { value: "Harbin", label: "Harbin" },
  { value: "Havana", label: "Havana" },
  { value: "Heidelberg", label: "Heidelberg" },
  { value: "Helsinki", label: "Helsinki" },
  { value: "Heraklion", label: "Heraklion" },
  { value: "Hermosillo", label: "Hermosillo" },
  { value: "Ho Chi Minh City", label: "Ho Chi Minh City" },
  { value: "Hobart", label: "Hobart" },
  { value: "Homs", label: "Homs" },
  { value: "Hong Kong", label: "Hong Kong" },
  { value: "Honolulu", label: "Honolulu" },
  { value: "Houston", label: "Houston" },
  { value: "Huambo", label: "Huambo" },
  { value: "Hue", label: "Hue" },
  { value: "Huelva", label: "Huelva" },
  { value: "Ibiza Town", label: "Ibiza Town" },
  { value: "Iloilo City", label: "Iloilo City" },
  { value: "Indianapolis", label: "Indianapolis" },
  { value: "Innsbruck", label: "Innsbruck" },
  { value: "Islamabad", label: "Islamabad" },
  { value: "Istanbul", label: "Istanbul" },
  { value: "Izmir", label: "Izmir" },
  { value: "Jackson", label: "Jackson" },
  { value: "Jacksonville", label: "Jacksonville" },
  { value: "Jaipur", label: "Jaipur" },
  { value: "Jakarta", label: "Jakarta" },
  { value: "Jalalabad", label: "Jalalabad" },
  { value: "Jamestown", label: "Jamestown" },
  { value: "Jeddah", label: "Jeddah" },
  { value: "Jeju City", label: "Jeju City" },
  { value: "Jerusalem", label: "Jerusalem" },
  { value: "Jinja", label: "Jinja" },
  { value: "João Pessoa", label: "João Pessoa" },
  { value: "Johannesburg", label: "Johannesburg" },
  { value: "Juneau", label: "Juneau" },
  { value: "Kabul", label: "Kabul" },
  { value: "Kaduna", label: "Kaduna" },
  { value: "Kagoshima", label: "Kagoshima" },
  { value: "Kahului", label: "Kahului" },
  { value: "Kampala", label: "Kampala" },
  { value: "Kano", label: "Kano" },
  { value: "Kanpur", label: "Kanpur" },
  { value: "Kansas City", label: "Kansas City" },
  { value: "Kaohsiung", label: "Kaohsiung" },
  { value: "Karachi", label: "Karachi" },
  { value: "Kathmandu", label: "Kathmandu" },
  { value: "Katsina", label: "Katsina" },
  { value: "Kaunas", label: "Kaunas" },
  { value: "Kawasaki", label: "Kawasaki" },
  { value: "Kazan", label: "Kazan" },
  { value: "Kermanshah", label: "Kermanshah" },
  { value: "Khartoum", label: "Khartoum" },
  { value: "Khobar", label: "Khobar" },
  { value: "Khon Kaen", label: "Khon Kaen" },
  { value: "Kigali", label: "Kigali" },
  { value: "Kilkenny", label: "Kilkenny" },
  { value: "Kimberley", label: "Kimberley" },
  { value: "Kingston", label: "Kingston" },
  { value: "Kinshasa", label: "Kinshasa" },
  { value: "Kirkuk", label: "Kirkuk" },
  { value: "Kisangani", label: "Kisangani" },
  { value: "Kisumu", label: "Kisumu" },
  { value: "Kitakyushu", label: "Kitakyushu" },
  { value: "Kitchener", label: "Kitchener" },
  { value: "Kitwe", label: "Kitwe" },
  { value: "Kochi", label: "Kochi" },
  { value: "Koforidua", label: "Koforidua" },
  { value: "Kohima", label: "Kohima" },
  { value: "Kolkata", label: "Kolkata" },
  { value: "Konya", label: "Konya" },
  { value: "Kota Kinabalu", label: "Kota Kinabalu" },
  { value: "Krakow", label: "Krakow" },
  { value: "Kralendijk", label: "Kralendijk" },
  { value: "Krasnodar", label: "Krasnodar" },
  { value: "Krasnoyarsk", label: "Krasnoyarsk" },
  { value: "Kuala Lumpur", label: "Kuala Lumpur" },
  { value: "Kuantan", label: "Kuantan" },
  { value: "Kuching", label: "Kuching" },
  { value: "Kumamoto", label: "Kumamoto" },
  { value: "Kunming", label: "Kunming" },
  { value: "Kuopio", label: "Kuopio" },
  { value: "Kuwait City", label: "Kuwait City" },
  { value: "Kyoto", label: "Kyoto" },
  { value: "La Paz", label: "La Paz" },
  { value: "La Plata", label: "La Plata" },
  { value: "Lagos", label: "Lagos" },
  { value: "Lahore", label: "Lahore" },
  { value: "Lahti", label: "Lahti" },
  { value: "Lanzhou", label: "Lanzhou" },
  { value: "Lapu-Lapu City", label: "Lapu-Lapu City" },
  { value: "Las Palmas de Gran Canaria", label: "Las Palmas de Gran Canaria" },
  { value: "Las Vegas", label: "Las Vegas" },
  { value: "Leeds", label: "Leeds" },
  { value: "Leipzig", label: "Leipzig" },
  { value: "Lethbridge", label: "Lethbridge" },
  { value: "Lhasa", label: "Lhasa" },
  { value: "Libreville", label: "Libreville" },
  { value: "Lilongwe", label: "Lilongwe" },
  { value: "Lima", label: "Lima" },
  { value: "Limassol", label: "Limassol" },
  { value: "Lincoln", label: "Lincoln" },
  { value: "Linz", label: "Linz" },
  { value: "Lisbon", label: "Lisbon" },
  { value: "Ljubljana", label: "Ljubljana" },
  { value: "Lome", label: "Lome" },
  { value: "London", label: "London" },
  { value: "Londrina", label: "Londrina" },
  { value: "Long Beach", label: "Long Beach" },
  { value: "Los Angeles", label: "Los Angeles" },
  { value: "Louisville", label: "Louisville" },
  { value: "Luanda", label: "Luanda" },
  { value: "Lubumbashi", label: "Lubumbashi" },
  { value: "Lucknow", label: "Lucknow" },
  { value: "Ludhiana", label: "Ludhiana" },
  { value: "Lusaka", label: "Lusaka" },
  { value: "Luxembourg City", label: "Luxembourg City" },
  { value: "Lviv", label: "Lviv" },
  { value: "Lyon", label: "Lyon" },
  { value: "Maastricht", label: "Maastricht" },
  { value: "Macau", label: "Macau" },
  { value: "Maceio", label: "Maceio" },
  { value: "Machala", label: "Machala" },
  { value: "Madison", label: "Madison" },
  { value: "Madrid", label: "Madrid" },
  { value: "Mahe", label: "Mahe" },
  { value: "Makassar", label: "Makassar" },
  { value: "Malabo", label: "Malabo" },
  { value: "Malaga", label: "Malaga" },
  { value: "Malang", label: "Malang" },
  { value: "Malatya", label: "Malatya" },
  { value: "Malmo", label: "Malmo" },
  { value: "Managua", label: "Managua" },
  { value: "Manama", label: "Manama" },
  { value: "Manchester", label: "Manchester" },
  { value: "Manila", label: "Manila" },
  { value: "Maputo", label: "Maputo" },
  { value: "Maracaibo", label: "Maracaibo" },
  { value: "Maracay", label: "Maracay" },
  { value: "Marrakech", label: "Marrakech" },
  { value: "Marseille", label: "Marseille" },
  { value: "Mashhad", label: "Mashhad" },
  { value: "Masqat", label: "Masqat" },
  { value: "Mataram", label: "Mataram" },
  { value: "Maturin", label: "Maturin" },
  { value: "Mayaguez", label: "Mayaguez" },
  { value: "Mazatlan", label: "Mazatlan" },
  { value: "Mbabane", label: "Mbabane" },
  { value: "Medan", label: "Medan" },
  { value: "Medellin", label: "Medellin" },
  { value: "Meknes", label: "Meknes" },
  { value: "Melbourne", label: "Melbourne" },
  { value: "Memphis", label: "Memphis" },
  { value: "Mendoza", label: "Mendoza" },
  { value: "Merida", label: "Merida" },
  { value: "Mersin", label: "Mersin" },
  { value: "Mexicali", label: "Mexicali" },
  { value: "Mexico City", label: "Mexico City" },
  { value: "Miami", label: "Miami" },
  { value: "Milan", label: "Milan" },
  { value: "Milwaukee", label: "Milwaukee" },
  { value: "Minneapolis", label: "Minneapolis" },
  { value: "Minsk", label: "Minsk" },
  { value: "Mogadishu", label: "Mogadishu" },
  { value: "Mombasa", label: "Mombasa" },
  { value: "Monrovia", label: "Monrovia" },
  { value: "Monterrey", label: "Monterrey" },
  { value: "Montevideo", label: "Montevideo" },
  { value: "Montgomery", label: "Montgomery" },
  { value: "Montpellier", label: "Montpellier" },
  { value: "Montreal", label: "Montreal" },
  { value: "Moscow", label: "Moscow" },
  { value: "Mumbai", label: "Mumbai" },
  { value: "Munich", label: "Munich" },
  { value: "Murcia", label: "Murcia" },
  { value: "N'Djamena", label: "N'Djamena" },
  { value: "Nagoya", label: "Nagoya" },
  { value: "Nairobi", label: "Nairobi" },
  { value: "Nakhon Ratchasima", label: "Nakhon Ratchasima" },
  { value: "Nanchang", label: "Nanchang" },
  { value: "Nanning", label: "Nanning" },
  { value: "Nantes", label: "Nantes" },
  { value: "Naples", label: "Naples" },
  { value: "Nashville", label: "Nashville" },
  { value: "Nassau", label: "Nassau" },
  { value: "Ndola", label: "Ndola" },
  { value: "New Delhi", label: "New Delhi" },
  { value: "New Orleans", label: "New Orleans" },
  { value: "New York City", label: "New York City" },
  { value: "Newcastle", label: "Newcastle" },
  { value: "Niamey", label: "Niamey" },
  { value: "Nice", label: "Nice" },
  { value: "Nicosia", label: "Nicosia" },
  { value: "Nizhny Novgorod", label: "Nizhny Novgorod" },
  { value: "Norfolk", label: "Norfolk" },
  { value: "Nouakchott", label: "Nouakchott" },
  { value: "Nuremberg", label: "Nuremberg" },
  { value: "Nur-Sultan", label: "Nur-Sultan" },
  { value: "Oaxaca", label: "Oaxaca" },
  { value: "Odessa", label: "Odessa" },
  { value: "Ogbomosho", label: "Ogbomosho" },
  { value: "Okayama", label: "Okayama" },
  { value: "Oklahoma City", label: "Oklahoma City" },
  { value: "Olongapo", label: "Olongapo" },
  { value: "Oman", label: "Oman" },
  { value: "Omsk", label: "Omsk" },
  { value: "Oradea", label: "Oradea" },
  { value: "Osaka", label: "Osaka" },
  { value: "Oslo", label: "Oslo" },
  { value: "Ostrava", label: "Ostrava" },
  { value: "Ouagadougou", label: "Ouagadougou" },
  { value: "Oulu", label: "Oulu" },
  { value: "Oviedo", label: "Oviedo" },
  { value: "Owerri", label: "Owerri" },
  { value: "Oxford", label: "Oxford" },
  { value: "Oxnard", label: "Oxnard" },
  { value: "Pachuca", label: "Pachuca" },
  { value: "Pago Pago", label: "Pago Pago" },
  { value: "Palermo", label: "Palermo" },
  { value: "Palma de Mallorca", label: "Palma de Mallorca" },
  { value: "Panama City", label: "Panama City" },
  { value: "Papeete", label: "Papeete" },
  { value: "Paramaribo", label: "Paramaribo" },
  { value: "Paris", label: "Paris" },
  { value: "Patras", label: "Patras" },
  { value: "Pekanbaru", label: "Pekanbaru" },
  { value: "Pembroke Pines", label: "Pembroke Pines" },
  { value: "Penza", label: "Penza" },
  { value: "Pereira", label: "Pereira" },
  { value: "Perth", label: "Perth" },
  { value: "Peshawar", label: "Peshawar" },
  { value: "Philadelphia", label: "Philadelphia" },
  { value: "Phnom Penh", label: "Phnom Penh" },
  { value: "Phoenix", label: "Phoenix" },
  { value: "Piatra Neamt", label: "Piatra Neamt" },
  { value: "Pittsburgh", label: "Pittsburgh" },
  { value: "Pleven", label: "Pleven" },
  { value: "Pleven", label: "Pleven" },
  { value: "Plovdiv", label: "Plovdiv" },
  { value: "Pohang", label: "Pohang" },
  { value: "Pointe-Noire", label: "Pointe-Noire" },
  { value: "Poitiers", label: "Poitiers" },
  { value: "Ponce", label: "Ponce" },
  { value: "Ponta Delgada", label: "Ponta Delgada" },
  { value: "Port Elizabeth", label: "Port Elizabeth" },
  { value: "Port Harcourt", label: "Port Harcourt" },
  { value: "Port Louis", label: "Port Louis" },
  { value: "Port Moresby", label: "Port Moresby" },
  { value: "Port-au-Prince", label: "Port-au-Prince" },
  { value: "Portland", label: "Portland" },
  { value: "Porto", label: "Porto" },
  { value: "Porto Alegre", label: "Porto Alegre" },
  { value: "Porto Novo", label: "Porto Novo" },
  { value: "Porto Velho", label: "Porto Velho" },
  { value: "Portsmouth", label: "Portsmouth" },
  { value: "Posadas", label: "Posadas" },
  { value: "Potosi", label: "Potosi" },
  { value: "Poznan", label: "Poznan" },
  { value: "Prague", label: "Prague" },
  { value: "Praia", label: "Praia" },
  { value: "Presov", label: "Presov" },
  { value: "Pretoria", label: "Pretoria" },
  { value: "Pristina", label: "Pristina" },
  { value: "Providence", label: "Providence" },
  { value: "Puebla", label: "Puebla" },
  { value: "Puente Alto", label: "Puente Alto" },
  { value: "Puerto Montt", label: "Puerto Montt" },
  { value: "Puerto Princesa", label: "Puerto Princesa" },
  { value: "Puerto Vallarta", label: "Puerto Vallarta" },
  { value: "Pune", label: "Pune" },
  { value: "Punta Arenas", label: "Punta Arenas" },
  { value: "Punto Fijo", label: "Punto Fijo" },
  { value: "Pyongyang", label: "Pyongyang" },
  { value: "Qatar", label: "Qatar" },
  { value: "Qazvin", label: "Qazvin" },
  { value: "Qingdao", label: "Qingdao" },
  { value: "Qom", label: "Qom" },
  { value: "Quanzhou", label: "Quanzhou" },
  { value: "Quebec City", label: "Quebec City" },
  { value: "Queimados", label: "Queimados" },
  { value: "Quelimane", label: "Quelimane" },
  { value: "Queretaro", label: "Queretaro" },
  { value: "Quetta", label: "Quetta" },
  { value: "Quibdo", label: "Quibdo" },
  { value: "Quilmes", label: "Quilmes" },
  { value: "Quito", label: "Quito" },
  { value: "Rabat", label: "Rabat" },
  { value: "Rai Bareli", label: "Rai Bareli" },
  { value: "Raipur", label: "Raipur" },
  { value: "Rajkot", label: "Rajkot" },
  { value: "Raleigh", label: "Raleigh" },
  { value: "Rancagua", label: "Rancagua" },
  { value: "Rasht", label: "Rasht" },
  { value: "Reading", label: "Reading" },
  { value: "Recife", label: "Recife" },
  { value: "Regina", label: "Regina" },
  { value: "Reykjavik", label: "Reykjavik" },
  { value: "Rhodes", label: "Rhodes" },
  { value: "Richmond", label: "Richmond" },
  { value: "Riga", label: "Riga" },
  { value: "Rijeka", label: "Rijeka" },
  { value: "Rio Branco", label: "Rio Branco" },
  { value: "Rio de Janeiro", label: "Rio de Janeiro" },
  { value: "Riyadh", label: "Riyadh" },
  { value: "Roanoke", label: "Roanoke" },
  { value: "Rome", label: "Rome" },
  { value: "Rosario", label: "Rosario" },
  { value: "Rostov-on-Don", label: "Rostov-on-Don" },
  { value: "Rotterdam", label: "Rotterdam" },
  { value: "Rouen", label: "Rouen" },
  { value: "Ruse", label: "Ruse" },
  { value: "Rustenburg", label: "Rustenburg" },
  { value: "Ryazan", label: "Ryazan" },
  { value: "Sacramento", label: "Sacramento" },
  { value: "Safi", label: "Safi" },
  { value: "Sagamihara", label: "Sagamihara" },
  { value: "Saint Petersburg", label: "Saint Petersburg" },
  { value: "Saint-Denis", label: "Saint-Denis" },
  { value: "Saint-Dizier", label: "Saint-Dizier" },
  { value: "Saint-Étienne", label: "Saint-Étienne" },
  { value: "Saint-Louis", label: "Saint-Louis" },
  { value: "Saint-Pierre", label: "Saint-Pierre" },
  { value: "Saint-Quentin", label: "Saint-Quentin" },
  { value: "Saint-Tropez", label: "Saint-Tropez" },
  { value: "Saitama", label: "Saitama" },
  { value: "Sakai", label: "Sakai" },
  { value: "Sakai", label: "Sakai" },
  { value: "Salamanca", label: "Salamanca" },
  { value: "Salem", label: "Salem" },
  { value: "Salerno", label: "Salerno" },
  { value: "Salt Lake City", label: "Salt Lake City" },
  { value: "Salta", label: "Salta" },
  { value: "Salvador", label: "Salvador" },
  { value: "Samara", label: "Samara" },
  { value: "Samarinda", label: "Samarinda" },
  { value: "Samsun", label: "Samsun" },
  { value: "San Antonio", label: "San Antonio" },
  { value: "San Bernardino", label: "San Bernardino" },
  { value: "San Carlos", label: "San Carlos" },
  { value: "San Cristobal", label: "San Cristobal" },
  { value: "San Diego", label: "San Diego" },
  { value: "San Fernando", label: "San Fernando" },
  { value: "San Francisco", label: "San Francisco" },
  { value: "San Jose", label: "San Jose" },
  { value: "San Juan", label: "San Juan" },
  { value: "San Luis Potosi", label: "San Luis Potosi" },
  { value: "San Marino", label: "San Marino" },
  { value: "San Miguel", label: "San Miguel" },
  { value: "San Pedro", label: "San Pedro" },
  { value: "San Salvador", label: "San Salvador" },
  { value: "Sanaa", label: "Sanaa" },
  { value: "Sandton", label: "Sandton" },
  { value: "Sangli", label: "Sangli" },
  { value: "Santa Ana", label: "Santa Ana" },
  { value: "Santa Clara", label: "Santa Clara" },
  { value: "Santa Cruz", label: "Santa Cruz" },
  { value: "Santa Fe", label: "Santa Fe" },
  { value: "Santa Marta", label: "Santa Marta" },
  { value: "Santa Rosa", label: "Santa Rosa" },
  { value: "Santander", label: "Santander" },
  { value: "Santiago", label: "Santiago" },
  { value: "Santo Domingo", label: "Santo Domingo" },
  { value: "Santos", label: "Santos" },
  { value: "Sapporo", label: "Sapporo" },
  { value: "Sarajevo", label: "Sarajevo" },
  { value: "Saratov", label: "Saratov" },
  { value: "Sasebo", label: "Sasebo" },
  { value: "Saskatoon", label: "Saskatoon" },
  { value: "Satu Mare", label: "Satu Mare" },
  { value: "Savannah", label: "Savannah" },
  { value: "Seville", label: "Seville" },
  { value: "Semarang", label: "Semarang" },
  { value: "Sendai", label: "Sendai" },
  { value: "Seongnam", label: "Seongnam" },
  { value: "Seoul", label: "Seoul" },
  { value: "Seremban", label: "Seremban" },
  { value: "Seropédica", label: "Seropédica" },
  { value: "Serra", label: "Serra" },
  { value: "Setif", label: "Setif" },
  { value: "Setubal", label: "Setubal" },
  { value: "Sevastopol", label: "Sevastopol" },
  { value: "Shah Alam", label: "Shah Alam" },
  { value: "Shanghai", label: "Shanghai" },
  { value: "Shantou", label: "Shantou" },
  { value: "Sharjah", label: "Sharjah" },
  { value: "Shchyolkovo", label: "Shchyolkovo" },
  { value: "Sheffield", label: "Sheffield" },
  { value: "Shenyang", label: "Shenyang" },
  { value: "Shenzhen", label: "Shenzhen" },
  { value: "Sherbrooke", label: "Sherbrooke" },
  { value: "Shijiazhuang", label: "Shijiazhuang" },
  { value: "Shillong", label: "Shillong" },
  { value: "Shimla", label: "Shimla" },
  { value: "Shiraz", label: "Shiraz" },
  { value: "Shiyan", label: "Shiyan" },
  { value: "Shizuoka", label: "Shizuoka" },
  { value: "Shymkent", label: "Shymkent" },
  { value: "Sialkot", label: "Sialkot" },
  { value: "Siem Reap", label: "Siem Reap" },
  { value: "Siena", label: "Siena" },
  { value: "Suez", label: "Suez" },
  { value: "Sucre", label: "Sucre" },
  { value: "Sudbury", label: "Sudbury" },
  { value: "Suez", label: "Suez" },
  { value: "Suihua", label: "Suihua" },
  { value: "Suining", label: "Suining" },
  { value: "Suita", label: "Suita" },
  { value: "Sukabumi", label: "Sukabumi" },
  { value: "Sukkur", label: "Sukkur" },
  { value: "Sullana", label: "Sullana" },
  { value: "Sumare", label: "Sumare" },
  { value: "Sunderland", label: "Sunderland" },
  { value: "Surabaya", label: "Surabaya" },
  { value: "Surakarta", label: "Surakarta" },
  { value: "Surat", label: "Surat" },
  { value: "Surrey", label: "Surrey" },
  { value: "Suva", label: "Suva" },
  { value: "Suzhou", label: "Suzhou" },
  { value: "Suzhou", label: "Suzhou" },
  { value: "Swansea", label: "Swansea" },
  { value: "Sydney", label: "Sydney" },
  { value: "Sylhet", label: "Sylhet" },
  { value: "Syracuse", label: "Syracuse" },
  { value: "Taboão da Serra", label: "Taboão da Serra" },
  { value: "Tabriz", label: "Tabriz" },
  { value: "Taichung", label: "Taichung" },
  { value: "Tainan", label: "Tainan" },
  { value: "Taipei", label: "Taipei" },
  { value: "Taitung City", label: "Taitung City" },
  { value: "Taiyuan", label: "Taiyuan" },
  { value: "Tallinn", label: "Tallinn" },
  { value: "Tama", label: "Tama" },
  { value: "Tampere", label: "Tampere" },
  { value: "Tampico", label: "Tampico" },
  { value: "Tangerang", label: "Tangerang" },
  { value: "Tangier", label: "Tangier" },
  { value: "Taoyuan City", label: "Taoyuan City" },
  { value: "Tarija", label: "Tarija" },
  { value: "Tarlac City", label: "Tarlac City" },
  { value: "Tarragona", label: "Tarragona" },
  { value: "Tartu", label: "Tartu" },
  { value: "Tashkent", label: "Tashkent" },
  { value: "Taubate", label: "Taubate" },
  { value: "Tbilisi", label: "Tbilisi" },
  { value: "Tegucigalpa", label: "Tegucigalpa" },
  { value: "Tehran", label: "Tehran" },
  { value: "Tel Aviv", label: "Tel Aviv" },
  { value: "Tema", label: "Tema" },
  { value: "Temuco", label: "Temuco" },
  { value: "Tengzhou", label: "Tengzhou" },
  { value: "Teresina", label: "Teresina" },
  { value: "Teresopolis", label: "Teresopolis" },
  { value: "Terni", label: "Terni" },
  { value: "Tetouan", label: "Tetouan" },
  { value: "Thane", label: "Thane" },
  { value: "The Hague", label: "The Hague" },
  { value: "Thessaloniki", label: "Thessaloniki" },
  { value: "Thimphu", label: "Thimphu" },
  { value: "Thousand Oaks", label: "Thousand Oaks" },
  { value: "Thunder Bay", label: "Thunder Bay" },
  { value: "Tianjin", label: "Tianjin" },
  { value: "Tijuana", label: "Tijuana" },
  { value: "Tirana", label: "Tirana" },
  { value: "Tiruchirappalli", label: "Tiruchirappalli" },
  { value: "Tirupati", label: "Tirupati" },
  { value: "Tlalnepantla", label: "Tlalnepantla" },
  { value: "Tlaxcala", label: "Tlaxcala" },
  { value: "Toamasina", label: "Toamasina" },
  { value: "Tokorozawa", label: "Tokorozawa" },
  { value: "Tokushima", label: "Tokushima" },
  { value: "Tokyo", label: "Tokyo" },
  { value: "Toledo", label: "Toledo" },
  { value: "Toluca", label: "Toluca" },
  { value: "Tomakomai", label: "Tomakomai" },
  { value: "Tomsk", label: "Tomsk" },
  { value: "Tondabayashi", label: "Tondabayashi" },
  { value: "Toronto", label: "Toronto" },
  { value: "Torrance", label: "Torrance" },
  { value: "Torreón", label: "Torreón" },
  { value: "Tottori", label: "Tottori" },
  { value: "Toulon", label: "Toulon" },
  { value: "Toulouse", label: "Toulouse" },
  { value: "Tours", label: "Tours" },
  { value: "Townsville", label: "Townsville" },
  { value: "Toyama", label: "Toyama" },
  { value: "Toyohashi", label: "Toyohashi" },
  { value: "Toyota", label: "Toyota" },
  { value: "Trabzon", label: "Trabzon" },
  { value: "Trento", label: "Trento" },
  { value: "Trieste", label: "Trieste" },
  { value: "Trinidad", label: "Trinidad" },
  { value: "Tripoli", label: "Tripoli" },
  { value: "Trois-Rivieres", label: "Trois-Rivieres" },
  { value: "Tromso", label: "Tromso" },
  { value: "Trondheim", label: "Trondheim" },
  { value: "Troyes", label: "Troyes" },
  { value: "Tsu", label: "Tsu" },
  { value: "Tsukuba", label: "Tsukuba" },
  { value: "Tucuman", label: "Tucuman" },
  { value: "Tucson", label: "Tucson" },
  { value: "Tuguegarao", label: "Tuguegarao" },
  { value: "Tulsa", label: "Tulsa" },
  { value: "Tunis", label: "Tunis" },
  { value: "Turin", label: "Turin" },
  { value: "Turku", label: "Turku" },
  { value: "Tuxtla Gutierrez", label: "Tuxtla Gutierrez" },
  { value: "Tuxtla Gutierrez", label: "Tuxtla Gutierrez" },
  { value: "Ube", label: "Ube" },
  { value: "Uberaba", label: "Uberaba" },
  { value: "Uberlandia", label: "Uberlandia" },
  { value: "Udaipur", label: "Udaipur" },
  { value: "Udon Thani", label: "Udon Thani" },
  { value: "Ufa", label: "Ufa" },
  { value: "Ujjain", label: "Ujjain" },
  { value: "Ulaanbaatar", label: "Ulaanbaatar" },
  { value: "Ulan-Ude", label: "Ulan-Ude" },
  { value: "Ulsan", label: "Ulsan" },
  { value: "Umea", label: "Umea" },
  { value: "Umm Durman", label: "Umm Durman" },
  { value: "Umuahia", label: "Umuahia" },
  { value: "Upland", label: "Upland" },
  { value: "Uppsala", label: "Uppsala" },
  { value: "Urasoe", label: "Urasoe" },
  { value: "Urayasu", label: "Urayasu" },
  { value: "Urmia", label: "Urmia" },
  { value: "Uruapan", label: "Uruapan" },
  { value: "Urumqi", label: "Urumqi" },
  { value: "Usak", label: "Usak" },
  { value: "Usuki", label: "Usuki" },
  { value: "Utsunomiya", label: "Utsunomiya" },
  { value: "Uvira", label: "Uvira" },
  { value: "Uyo", label: "Uyo" },
  { value: "Uzhhorod", label: "Uzhhorod" },
  { value: "Vadodara", label: "Vadodara" },
  { value: "Valdivia", label: "Valdivia" },
  { value: "Valencia", label: "Valencia" },
  { value: "Valenzuela", label: "Valenzuela" },
  { value: "Valera", label: "Valera" },
  { value: "Valladolid", label: "Valladolid" },
  { value: "Valledupar", label: "Valledupar" },
  { value: "Valletta", label: "Valletta" },
  { value: "Valparaiso", label: "Valparaiso" },
  { value: "Van", label: "Van" },
  { value: "Vancouver", label: "Vancouver" },
  { value: "Varna", label: "Varna" },
  { value: "Vasteras", label: "Vasteras" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venice", label: "Venice" },
  { value: "Veracruz", label: "Veracruz" },
  { value: "Vereeniging", label: "Vereeniging" },
  { value: "Verona", label: "Verona" },
  { value: "Victoria", label: "Victoria" },
  { value: "Vienna", label: "Vienna" },
  { value: "Vientiane", label: "Vientiane" },
  { value: "Vigo", label: "Vigo" },
  { value: "Vijayawada", label: "Vijayawada" },
  { value: "Vila Nova de Gaia", label: "Vila Nova de Gaia" },
  { value: "Vilnius", label: "Vilnius" },
  { value: "Vina del Mar", label: "Vina del Mar" },
  { value: "Vinh", label: "Vinh" },
  { value: "Viransehir", label: "Viransehir" },
  { value: "Vitoria", label: "Vitoria" },
  { value: "Vladikavkaz", label: "Vladikavkaz" },
  { value: "Vladivostok", label: "Vladivostok" },
  { value: "Volgodonsk", label: "Volgodonsk" },
  { value: "Volgograd", label: "Volgograd" },
  { value: "Volos", label: "Volos" },
  { value: "Vorkuta", label: "Vorkuta" },
  { value: "Voronezh", label: "Voronezh" },
  { value: "Waco", label: "Waco" },
  { value: "Wakayama", label: "Wakayama" },
  { value: "Walbrzych", label: "Walbrzych" },
  { value: "Walsall", label: "Walsall" },
  { value: "Warrington", label: "Warrington" },
  { value: "Warsaw", label: "Warsaw" },
  { value: "Washington, D.C.", label: "Washington, D.C." },
  { value: "Wenzhou", label: "Wenzhou" },
  { value: "Whakatane", label: "Whakatane" },
  { value: "Wichita", label: "Wichita" },
  { value: "Willemstad", label: "Willemstad" },
  { value: "Windhoek", label: "Windhoek" },
  { value: "Windsor", label: "Windsor" },
  { value: "Winnipeg", label: "Winnipeg" },
  { value: "Winston-Salem", label: "Winston-Salem" },
  { value: "Wollongong", label: "Wollongong" },
  { value: "Wolverhampton", label: "Wolverhampton" },
  { value: "Wroclaw", label: "Wroclaw" },
  { value: "Wuhan", label: "Wuhan" },
  { value: "Wuhu", label: "Wuhu" },
  { value: "Wuppertal", label: "Wuppertal" },
  { value: "Wuxi", label: "Wuxi" },
  { value: "Wuyishan", label: "Wuyishan" },
  { value: "Wyong", label: "Wyong" },
  { value: "Wyong", label: "Wyong" },
  { value: "Xalapa", label: "Xalapa" },
  { value: "Xiamen", label: "Xiamen" },
  { value: "Xiangyang", label: "Xiangyang" },
  { value: "Xianyang", label: "Xianyang" },
  { value: "Xi'an", label: "Xi'an" },
  { value: "Xining", label: "Xining" },
  { value: "Xinxiang", label: "Xinxiang" },
  { value: "Xinyang", label: "Xinyang" },
  { value: "Xuzhou", label: "Xuzhou" },
  { value: "Yamagata", label: "Yamagata" },
  { value: "Yamaguchi", label: "Yamaguchi" },
  { value: "Yamato", label: "Yamato" },
  { value: "Yamoussoukro", label: "Yamoussoukro" },
  { value: "Yancheng", label: "Yancheng" },
  { value: "Yangon", label: "Yangon" },
  { value: "Yangzhou", label: "Yangzhou" },
  { value: "Yantai", label: "Yantai" },
  { value: "Yaounde", label: "Yaounde" },
  { value: "Yekaterinburg", label: "Yekaterinburg" },
  { value: "Yerevan", label: "Yerevan" },
  { value: "Yinchuan", label: "Yinchuan" },
  { value: "Yogyakarta", label: "Yogyakarta" },
  { value: "Yokkaichi", label: "Yokkaichi" },
  { value: "Yokohama", label: "Yokohama" },
  { value: "Yonkers", label: "Yonkers" },
  { value: "Yopal", label: "Yopal" },
  { value: "Yulin", label: "Yulin" },
  { value: "Yuzhno-Sakhalinsk", label: "Yuzhno-Sakhalinsk" },
  { value: "Zagreb", label: "Zagreb" },
  { value: "Zahedan", label: "Zahedan" },
  { value: "Zaoyang", label: "Zaoyang" },
  { value: "Zaozhuang", label: "Zaozhuang" },
  { value: "Zapopan", label: "Zapopan" },
  { value: "Zaragoza", label: "Zaragoza" },
  { value: "Zaria", label: "Zaria" },
  { value: "Zelenograd", label: "Zelenograd" },
  { value: "Zenica", label: "Zenica" },
  { value: "Zhangjiakou", label: "Zhangjiakou" },
  { value: "Zhangzhou", label: "Zhangzhou" },
  { value: "Zhanjiang", label: "Zhanjiang" },
  { value: "Zhaoqing", label: "Zhaoqing" },
  { value: "Zhaotong", label: "Zhaotong" },
  { value: "Zhengzhou", label: "Zhengzhou" },
  { value: "Zhenjiang", label: "Zhenjiang" },
  { value: "Zhongshan", label: "Zhongshan" },
  { value: "Zhoushan", label: "Zhoushan" },
  { value: "Zhuhai", label: "Zhuhai" },
  { value: "Zhuzhou", label: "Zhuzhou" },
  { value: "Zhytomyr", label: "Zhytomyr" },
  { value: "Zibo", label: "Zibo" },
  { value: "Zielona Gora", label: "Zielona Gora" },
  { value: "Ziguinchor", label: "Ziguinchor" },
  { value: "Zinder", label: "Zinder" },
  { value: "Zlatoust", label: "Zlatoust" },
  { value: "Zonguldak", label: "Zonguldak" },
  { value: "Zrenjanin", label: "Zrenjanin" },
  { value: "Zurich", label: "Zurich" },
  { value: "Zvishavane", label: "Zvishavane" },
  { value: "Zwedru", label: "Zwedru" },
  { value: "Zwevegem", label: "Zwevegem" },
  { value: "Zwickau", label: "Zwickau" },
  { value: "Zwolle", label: "Zwolle" },
  { value: "Zyryanovsk", label: "Zyryanovsk" },
  { value: "Zyrardow", label: "Zyrardow" },
  { value: "Zyryanovsk", label: "Zyryanovsk" },
  { value: "Zywiec", label: "Zywiec" },
  { value: "Zyzomysl", label: "Zyzomysl" },
  { value: "Zzyzx", label: "Zzyzx" },
];

export const typeOfFirmOptions = [
  { value: "Belgian", label: "Belgian" },
  { value: "Benelux", label: "Benelux" },
  { value: "International", label: "International" },
  { value: "Niche", label: "Niche" },
  { value: "Full-service-firm", label: "Full-service firm" },
  { value: "No-preference", label: "No preference" },
];

export const expertiseOptions = [
  {
    value: "Antitrust",
    label: "Antitrust",
  },
  {
    value: "Aviation",
    label: "Aviation",
  },
  {
    value: "Banking & Finance",
    label: "Banking & Finance",
  },
  {
    value: "Civil law",
    label: "Civil law",
  },
  {
    value: "Commercial",
    label: "Commercial",
  },
  {
    value: "Competition",
    label: "Competition",
  },
  {
    value: "Compliance",
    label: "Compliance",
  },
  {
    value: "Corporate",
    label: "Corporate",
  },
  {
    value: "Criminal",
    label: "Criminal",
  },
  {
    value: "Cybersecurity",
    label: "Cybersecurity",
  },
  {
    value: "Employment",
    label: "Employment",
  },
  {
    value: "Energy",
    label: "Energy",
  },
  {
    value: "Environmental",
    label: "Environmental",
  },
  {
    value: "Estate planning",
    label: "Estate planning",
  },
  {
    value: "EU Law",
    label: "EU Law",
  },
  {
    value: "Family Law",
    label: "Family Law",
  },
  {
    value: "ICT & Data protection",
    label: "ICT & Data protection",
  },
  {
    value: "Insurance",
    label: "Insurance",
  },
  {
    value: "Investment funds",
    label: "Investment funds",
  },
  {
    value: "IP",
    label: "IP",
  },
  {
    value: "Life Sciences",
    label: "Life Sciences",
  },
  {
    value: "Litigation / ADR",
    label: "Litigation / ADR",
  },
  {
    value: "Maritime law",
    label: "Maritime law",
  },
  {
    value: "Privacy",
    label: "Privacy",
  },
  {
    value: "Public",
    label: "Public",
  },
  {
    value: "Real Estate",
    label: "Real Estate",
  },
  {
    value: "Regulatory",
    label: "Regulatory",
  },
  {
    value: "Retail",
    label: "Retail",
  },
  {
    value: "Tax",
    label: "Tax",
  },
  {
    value: "International Trade Law",
    label: "International Trade Law",
  },
  {
    value: "Transport",
    label: "Transport",
  },
  {
    value: "UCITS",
    label: "UCITS",
  },
  {
    value: "White Collar crime",
    label: "White Collar crime",
  },
];

export const skillOptions = {
  Aviation: [
    { value: "Airline Regulations", label: "Airline Regulations" },
    { value: "Aircraft Financing", label: "Aircraft Financing" },
    { value: "Litigation", label: "Litigation" },
    { value: "Compliance", label: "Compliance" },
    { value: "Insurance & Claims", label: "Insurance & Claims" },
  ],
  "Banking & Finance": [
    { value: "Regulatory", label: "Regulatory" },
    { value: "Transactions", label: "Transactions" },
    { value: "Securities Law", label: "Securities Law" },
    { value: "Corporate Finance", label: "Corporate Finance" },
    { value: "Investment Law", label: "Investment Law" },
    {
      value: "Debt and financial markets",
      label: "Debt and financial markets",
    },
    { value: "Capital markets", label: "Capital markets" },
    { value: "ESG", label: "ESG" },
  ],
  "Civil law": [
    { value: "Contract Law", label: "Contract Law" },
    { value: "Property Law", label: "Property Law" },
    { value: "Family Law", label: "Family Law" },
    { value: "Inheritance Law", label: "Inheritance Law" },
  ],
  Commercial: [
    { value: "Commercial Contracts", label: "Commercial Contracts" },
    { value: "Commercial Transactions", label: "Commercial Transactions" },
    { value: "Litigation & ADR", label: "Litigation & ADR" },
    {
      value: "Unfair competition and Consumer Protection",
      label: "Unfair competition and Consumer Protection",
    },
    { value: "Product Liability", label: "Product Liability" },
    { value: "Leasing & Factoring", label: "Leasing & Factoring" },
    {
      value: "Supply, distribution & agency agreements",
      label: "Supply, distribution & agency agreements",
    },
    { value: "Franchising", label: "Franchising" },
    {
      value: "General trade and sales conditions",
      label: "General trade and sales conditions",
    },
  ],
  Competition: [
    { value: "Antitrust", label: "Antitrust" },
    { value: "Merger Control", label: "Merger Control" },
    { value: "State Aid", label: "State Aid" },
    { value: "Abuse of Dominance", label: "Abuse of Dominance" },
    { value: "Cartel", label: "Cartel" },
    { value: "Litigation", label: "Litigation" },
    { value: "Compliance", label: "Compliance" },
    {
      value: "EU & Global Competition Law",
      label: "EU & Global Competition Law",
    },
    { value: "Belgian competition law", label: "Belgian competition law" },
  ],
  Compliance: [
    { value: "Regulatory Compliance", label: "Regulatory Compliance" },
    { value: "Corporate Governance", label: "Corporate Governance" },
    { value: "Risk Management", label: "Risk Management" },
    { value: "Internal Investigations", label: "Internal Investigations" },
    {
      value: "Anti-Money Laundering (AML) Compliance",
      label: "Anti-Money Laundering (AML) Compliance",
    },
  ],
  Corporate: [
    { value: "Corporate Governance", label: "Corporate Governance" },
    { value: "Mergers & Acquisitions", label: "Mergers & Acquisitions" },
    { value: "Corporate Finance", label: "Corporate Finance" },
    { value: "Due Diligence", label: "Due Diligence" },
    { value: "Shareholder disputes", label: "Shareholder disputes" },
  ],
  Criminal: [
    { value: "White Collar Crime", label: "White Collar Crime" },
    { value: "Criminal Procedure", label: "Criminal Procedure" },
    { value: "Financial criminal law", label: "Financial criminal law" },
    { value: "Common criminal law", label: "Common criminal law" },
    { value: "Criminal investigations", label: "Criminal investigations" },
  ],
  Cybersecurity: [
    { value: "Network Security", label: "Network Security" },
    { value: "Data Breach Response", label: "Data Breach Response" },
    { value: "Risk Management", label: "Risk Management" },
    { value: "Cyber Law", label: "Cyber Law" },
    { value: "Compliance", label: "Compliance" },
  ],
  Employment: [
    { value: "Employment law", label: "Employment law" },
    { value: "Social security law", label: "Social security law" },
    { value: "Litigation", label: "Litigation" },
    { value: "Cross-border", label: "Cross-border" },
    { value: "Compliance", label: "Compliance" },
    { value: "Collective employment law", label: "Collective employment law" },
    { value: "Employment contracts", label: "Employment contracts" },
    { value: "Privacy at work", label: "Privacy at work" },
    { value: "HR", label: "HR" },
    {
      value: "Employee Benefits and Compensation",
      label: "Employee Benefits and Compensation",
    },
  ],
  Energy: [
    { value: "Energy Regulation", label: "Energy Regulation" },
    { value: "Renewable Energy", label: "Renewable Energy" },
    { value: "Oil & Gas Law", label: "Oil & Gas Law" },
    { value: "Energy Transactions", label: "Energy Transactions" },
    { value: "Environmental Compliance", label: "Environmental Compliance" },
    { value: "Litigation", label: "Litigation" },
    { value: "M&A", label: "M&A" },
    { value: "Public procurement", label: "Public procurement" },
  ],
  Environmental: [
    { value: "Environmental Regulation", label: "Environmental Regulation" },
    { value: "Pollution Control", label: "Pollution Control" },
    { value: "Compliance", label: "Compliance" },
    { value: "ESG", label: "ESG" },
    { value: "Litigation", label: "Litigation" },
  ],
  "Estate planning": [
    { value: "Family governance", label: "Family governance" },
    { value: "Investments", label: "Investments" },
    { value: "Estate Administration", label: "Estate Administration" },
    { value: "Inheritance Tax Planning", label: "Inheritance Tax Planning" },
    { value: "Asset Protection", label: "Asset Protection" },
    { value: "Succession Planning", label: "Succession Planning" },
  ],
  "EU Law": [
    { value: "EU Law Regulations", label: "EU Law Regulations" },
    { value: "EU Competition Law", label: "EU Competition Law" },
    { value: "EU Trade Law", label: "EU Trade Law" },
    { value: "EU Environmental Law", label: "EU Environmental Law" },
    {
      value: "EU Data Protection Regulations",
      label: "EU Data Protection Regulations",
    },
    { value: "EU investigations", label: "EU investigations" },
    { value: "EU Litigation", label: "EU Litigation" },
    { value: "Compliance", label: "Compliance" },
  ],
  "Family Law": [
    { value: "Divorce Law", label: "Divorce Law" },
    { value: "Child Custody", label: "Child Custody" },
    { value: "Adoption Law", label: "Adoption Law" },
    { value: "Family Mediation", label: "Family Mediation" },
    { value: "Other", label: "Other" },
  ],
  "ICT & Data protection": [
    { value: "GDPR Compliance", label: "GDPR Compliance" },
    { value: "DPO", label: "DPO" },
    { value: "Litigation", label: "Litigation" },
    { value: "Cloud Computing Law", label: "Cloud Computing Law" },
    { value: "Data Breach", label: "Data Breach" },
    { value: "Privacy Policies", label: "Privacy Policies" },
  ],
  Insurance: [
    { value: "Insurance Regulation", label: "Insurance Regulation" },
    { value: "Claims Handling", label: "Claims Handling" },
    { value: "Compliance", label: "Compliance" },
    { value: "Policy Drafting", label: "Policy Drafting" },
    { value: "Litigation", label: "Litigation" },
  ],
  "Investment funds": [
    { value: "Fund Formation", label: "Fund Formation" },
    { value: "Regulatory Compliance", label: "Regulatory Compliance" },
    { value: "Asset Management", label: "Asset Management" },
    { value: "Transactions", label: "Transactions" },
    { value: "ESG", label: "ESG" },
  ],
  IP: [
    { value: "Patents", label: "Patents" },
    { value: "Trademarks", label: "Trademarks" },
    { value: "Copyrights", label: "Copyrights" },
    { value: "Trade Secrets", label: "Trade Secrets" },
    { value: "IP Litigation", label: "IP Litigation" },
    { value: "IP Transactions", label: "IP Transactions" },
  ],
  "Life Sciences": [
    { value: "Pharmaceutical Law", label: "Pharmaceutical Law" },
    { value: "Medical Device Law", label: "Medical Device Law" },
    { value: "Biotechnology Law", label: "Biotechnology Law" },
    { value: "Healthcare Compliance", label: "Healthcare Compliance" },
    {
      value: "Clinical Trials Regulation",
      label: "Clinical Trials Regulation",
    },
    { value: "Litigation", label: "Litigation" },
  ],
  "Litigation / ADR": [
    { value: "Civil Litigation", label: "Civil Litigation" },
    { value: "Commercial Litigation", label: "Commercial Litigation" },
    { value: "Arbitration", label: "Arbitration" },
    { value: "Mediation", label: "Mediation" },
    { value: "Corporate Litigation", label: "Corporate Litigation" },
    { value: "International Disputes", label: "International Disputes" },
  ],
  "Maritime law": [
    { value: "Shipping Regulation", label: "Shipping Regulation" },
    { value: "Marine Insurance", label: "Marine Insurance" },
    { value: "Admiralty Law", label: "Admiralty Law" },
    { value: "Cargo Claims", label: "Cargo Claims" },
    { value: "Charter Parties", label: "Charter Parties" },
    { value: "Litigation", label: "Litigation" },
  ],
  Privacy: [
    { value: "Data Protection Law", label: "Data Protection Law" },
    { value: "Privacy Compliance", label: "Privacy Compliance" },
    { value: "GDPR", label: "GDPR" },
    { value: "DPO", label: "DPO" },
    { value: "Litigation", label: "Litigation" },
  ],
  Public: [
    { value: "Administrative Law", label: "Administrative Law" },
    { value: "Constitutional Law", label: "Constitutional Law" },
    { value: "Public Procurement", label: "Public Procurement" },
    { value: "Regulatory Compliance", label: "Regulatory Compliance" },
    { value: "Litigation", label: "Litigation" },
  ],
  "Real Estate": [
    { value: "Property Transactions", label: "Property Transactions" },
    { value: "Real Estate Finance", label: "Real Estate Finance" },
    { value: "Land Use & Zoning", label: "Land Use & Zoning" },
    { value: "Construction Law", label: "Construction Law" },
    { value: "Litigation", label: "Litigation" },
  ],
  Regulatory: [
    { value: "Regulatory Compliance", label: "Regulatory Compliance" },
    {
      value: "Industry-Specific Regulations",
      label: "Industry-Specific Regulations",
    },
    { value: "Government Relations", label: "Government Relations" },
    { value: "Litigation", label: "Litigation" },
  ],
  Retail: [
    { value: "Retail Contracts", label: "Retail Contracts" },
    { value: "Consumer Protection", label: "Consumer Protection" },
    { value: "Commercial Transactions", label: "Commercial Transactions" },
    { value: "Litigation", label: "Litigation" },
    { value: "Compliance", label: "Compliance" },
  ],
  Tax: [
    { value: "Tax Compliance", label: "Tax Compliance" },
    { value: "Tax Planning", label: "Tax Planning" },
    { value: "Corporate Tax", label: "Corporate Tax" },
    { value: "International Tax", label: "International Tax" },
    { value: "VAT", label: "VAT" },
    { value: "Tax Litigation", label: "Tax Litigation" },
    { value: "Tax & Accounting", label: "Tax & Accounting" },
  ],
  "International Trade Law": [
    { value: "Trade Compliance", label: "Trade Compliance" },
    { value: "Customs Law", label: "Customs Law" },
    { value: "Export Controls", label: "Export Controls" },
    { value: "Trade Remedies", label: "Trade Remedies" },
    { value: "WTO Law", label: "WTO Law" },
  ],
  Transport: [
    { value: "Transport Regulation", label: "Transport Regulation" },
    { value: "Logistics Contracts", label: "Logistics Contracts" },
    { value: "Litigation", label: "Litigation" },
    { value: "Compliance", label: "Compliance" },
    { value: "Public procurement", label: "Public procurement" },
    { value: "Transport insurance", label: "Transport insurance" },
  ],
  UCITS: [
    { value: "Fund Compliance", label: "Fund Compliance" },
    { value: "Fund Management", label: "Fund Management" },
    { value: "Regulatory Compliance", label: "Regulatory Compliance" },
  ],
  "White Collar crime": [
    { value: "Fraud", label: "Fraud" },
    { value: "Embezzlement", label: "Embezzlement" },
    { value: "Bribery & Corruption", label: "Bribery & Corruption" },
    { value: "Money Laundering", label: "Money Laundering" },
    { value: "Compliance", label: "Compliance" },
    { value: "Litigation", label: "Litigation" },
    { value: "Internal Investigations", label: "Internal Investigations" },
  ],
};

export const firmsOptions = [
  {
    value: "& De Bandt",
    label: "& De Bandt",
  },
  {
    value: "Ace Law",
    label: "Ace Law",
  },
  {
    value: "Acontrario law",
    label: "Acontrario law",
  },
  {
    value: "acteo",
    label: "acteo",
  },
  {
    value: "Adapt Law",
    label: "Adapt Law",
  },
  {
    value: "AdaStone Law",
    label: "AdaStone Law",
  },
  {
    value: "Adhemar law",
    label: "Adhemar law",
  },
  {
    value: "Adius Advocaten",
    label: "Adius Advocaten",
  },
  {
    value: "Advant Beiten",
    label: "Advant Beiten",
  },
  {
    value: "Advant Nctm",
    label: "Advant Nctm",
  },
  {
    value: "Advisius",
    label: "Advisius",
  },
  {
    value: "Advocatenkantoor Beele",
    label: "Advocatenkantoor Beele",
  },
  {
    value: "Affluo Law",
    label: "Affluo Law",
  },
  {
    value: "Afschrift",
    label: "Afschrift",
  },
  {
    value: "Agio Legal",
    label: "Agio Legal",
  },
  {
    value: "AGLO's",
    label: "AGLO's",
  },
  {
    value: "AKD",
    label: "AKD",
  },
  {
    value: "A-LAW",
    label: "A-LAW",
  },
  {
    value: "Alber & Geiger",
    label: "Alber & Geiger",
  },
  {
    value: "Alfa Advocaten",
    label: "Alfa Advocaten",
  },
  {
    value: "Allen & Overy",
    label: "Allen & Overy",
  },
  {
    value: "Allen & Overy Bxl",
    label: "Allen & Overy Bxl",
  },
  {
    value: "Alston & Bird",
    label: "Alston & Bird",
  },
  {
    value: "ALTA law",
    label: "ALTA law",
  },
  {
    value: "Altea Advocaten",
    label: "Altea Advocaten",
  },
  {
    value: "ALTERYS",
    label: "ALTERYS",
  },
  {
    value: "Altius",
    label: "Altius",
  },
  {
    value: "Ambos",
    label: "Ambos",
  },
  {
    value: "Animo",
    label: "Animo",
  },
  {
    value: "Antaxius Advocaten",
    label: "Antaxius Advocaten",
  },
  {
    value: "APM Law - VDE Legal",
    label: "APM Law - VDE Legal",
  },
  {
    value: "Appleton Luff",
    label: "Appleton Luff",
  },
  {
    value: "Aramis",
    label: "Aramis",
  },
  {
    value: "Arcade law",
    label: "Arcade law",
  },
  {
    value: "Arcas Law",
    label: "Arcas Law",
  },
  {
    value: "Argo",
    label: "Argo",
  },
  {
    value: "Argus Advocaten",
    label: "Argus Advocaten",
  },
  {
    value: "Ariga",
    label: "Ariga",
  },
  {
    value: "Arnold & Porter",
    label: "Arnold & Porter",
  },
  {
    value: "Arnold Siedsma",
    label: "Arnold Siedsma",
  },
  {
    value: "Arteo Law",
    label: "Arteo Law",
  },
  {
    value: "Artes",
    label: "Artes",
  },
  {
    value: "Ashurst",
    label: "Ashurst",
  },
  {
    value: "Astrea",
    label: "Astrea",
  },
  {
    value: "Athena Law & Strategy",
    label: "Athena Law & Strategy",
  },
  {
    value: "AURION",
    label: "AURION",
  },
  {
    value: "Avocado rechtsanwälte",
    label: "Avocado rechtsanwälte",
  },
  {
    value: "Baker Botts",
    label: "Baker Botts",
  },
  {
    value: "Baker McKenzie",
    label: "Baker McKenzie",
  },
  {
    value: "Bannister",
    label: "Bannister",
  },
  {
    value: "Bazacle & Solon",
    label: "Bazacle & Solon",
  },
  {
    value: "BDL&T - Advocaten",
    label: "BDL&T - Advocaten",
  },
  {
    value: "Bellaw - Taquet, Clesse & Van Eeckhoutte",
    label: "Bellaw - Taquet, Clesse & Van Eeckhoutte",
  },
  {
    value: "Berenboom avocats",
    label: "Berenboom avocats",
  },
  {
    value: "Beyond Law Firm",
    label: "Beyond Law Firm",
  },
  {
    value: "Billiet & Co",
    label: "Billiet & Co",
  },
  {
    value: "Binck",
    label: "Binck",
  },
  {
    value: "Bird & Bird",
    label: "Bird & Bird",
  },
  {
    value: "Blienberg advocaten",
    label: "Blienberg advocaten",
  },
  {
    value: "Blixt",
    label: "Blixt",
  },
  {
    value: "Bloom Law",
    label: "Bloom Law",
  },
  {
    value: "Bofidi Legal",
    label: "Bofidi Legal",
  },
  {
    value: "Bonelli Erede Lombardi Pappalardo",
    label: "Bonelli Erede Lombardi Pappalardo",
  },
  {
    value: "Bredin Prat",
    label: "Bredin Prat",
  },
  {
    value: "Bryan Cave Leighton Paisner",
    label: "Bryan Cave Leighton Paisner",
  },
  {
    value: "Buyle Legal",
    label: "Buyle Legal",
  },
  {
    value: "Buyssens",
    label: "Buyssens",
  },
  {
    value: "Cadanz Law",
    label: "Cadanz Law",
  },
  {
    value: "Callens Law",
    label: "Callens Law",
  },
  {
    value: "Caluwaerts Uytterhoeven",
    label: "Caluwaerts Uytterhoeven",
  },
  {
    value: "Cambrian",
    label: "Cambrian",
  },
  {
    value: "Cape IP",
    label: "Cape IP",
  },
  {
    value: "Cartuyvels Nolf Terlinden Vinçotte",
    label: "Cartuyvels Nolf Terlinden Vinçotte",
  },
  {
    value: "Cazimir",
    label: "Cazimir",
  },
  {
    value: "CEW & Partners",
    label: "CEW & Partners",
  },
  {
    value: "Claes • Lehmann • Milde",
    label: "Claes • Lehmann • Milde",
  },
  {
    value: "Claeys & Engels",
    label: "Claeys & Engels",
  },
  {
    value: "Cleary Gottlieb Steen & Hamilton LLP",
    label: "Cleary Gottlieb Steen & Hamilton LLP",
  },
  {
    value: "Clifford Chance",
    label: "Clifford Chance",
  },
  {
    value: "Cloet & Partners",
    label: "Cloet & Partners",
  },
  {
    value: "CMS - EU Law office",
    label: "CMS - EU Law office",
  },
  {
    value: "CMS De Backer - Brussels",
    label: "CMS De Backer - Brussels",
  },
  {
    value: "Consenso",
    label: "Consenso",
  },
  {
    value: "Consiglio Law",
    label: "Consiglio Law",
  },
  {
    value: "Contrast European & Business Law",
    label: "Contrast European & Business Law",
  },
  {
    value: "Cooley",
    label: "Cooley",
  },
  {
    value: "Corbus",
    label: "Corbus",
  },
  {
    value: "CoTra",
    label: "CoTra",
  },
  {
    value: "Cottyn",
    label: "Cottyn",
  },
  {
    value: "Covington & Burling LLP",
    label: "Covington & Burling LLP",
  },
  {
    value: "Cresco",
    label: "Cresco",
  },
  {
    value: "Crivits & Persyn",
    label: "Crivits & Persyn",
  },
  {
    value: "Crowe Spark Legal",
    label: "Crowe Spark Legal",
  },
  {
    value: "Crowell & Moring",
    label: "Crowell & Moring",
  },
  {
    value: "Cuatrecasas",
    label: "Cuatrecasas",
  },
  {
    value: "Curia",
    label: "Curia",
  },
  {
    value: "Curtis, Mallet-Prevost, Colt & Mosle LLP",
    label: "Curtis, Mallet-Prevost, Colt & Mosle LLP",
  },
  {
    value: "D’Hondt de Caritat & Partners",
    label: "D’Hondt de Caritat & Partners",
  },
  {
    value: "Daldewolf",
    label: "Daldewolf",
  },
  {
    value: "De Beir Law + Mediation",
    label: "De Beir Law + Mediation",
  },
  {
    value: "De Bock & Baluwé",
    label: "De Bock & Baluwé",
  },
  {
    value: "De Brauw Blackstone Westbroek",
    label: "De Brauw Blackstone Westbroek",
  },
  {
    value: "De Broeck Van Laere & Partners",
    label: "De Broeck Van Laere & Partners",
  },
  {
    value: "De Clerck & Partners - DCP",
    label: "De Clerck & Partners - DCP",
  },
  {
    value: "De Coninck Law Firm",
    label: "De Coninck Law Firm",
  },
  {
    value: "De Groote - De Man",
    label: "De Groote - De Man",
  },
  {
    value: "Dechert Belgium",
    label: "Dechert Belgium",
  },
  {
    value: "Del-Law",
    label: "Del-Law",
  },
  {
    value: "Deloitte Legal",
    label: "Deloitte Legal",
  },
  {
    value: "Dentons",
    label: "Dentons",
  },
  {
    value: "Deprevernet Collective",
    label: "Deprevernet Collective",
  },
  {
    value: "Desdalex",
    label: "Desdalex",
  },
  {
    value: "Dewit law office",
    label: "Dewit law office",
  },
  {
    value: "DLA Piper LLP",
    label: "DLA Piper LLP",
  },
  {
    value: "DLPA Advocaten",
    label: "DLPA Advocaten",
  },
  {
    value: "Doutrelepont & Associés",
    label: "Doutrelepont & Associés",
  },
  {
    value: "Dugardyn & Partners Law Firm",
    label: "Dugardyn & Partners Law Firm",
  },
  {
    value: "Dumon, Sablon & Vanheeswijck",
    label: "Dumon, Sablon & Vanheeswijck",
  },
  {
    value: "DWMC",
    label: "DWMC",
  },
  {
    value: "e2.law",
    label: "e2.law",
  },
  {
    value: "ebl redsky",
    label: "ebl redsky",
  },
  {
    value: "Eeckhout Law Office",
    label: "Eeckhout Law Office",
  },
  {
    value: "Elegis",
    label: "Elegis",
  },
  {
    value: "Erkelens Law",
    label: "Erkelens Law",
  },
  {
    value: "Ethikos Lawyers",
    label: "Ethikos Lawyers",
  },
  {
    value: "Eubelius",
    label: "Eubelius",
  },
  {
    value: "Euclid",
    label: "Euclid",
  },
  {
    value: "Euregio Law & Tax",
    label: "Euregio Law & Tax",
  },
  {
    value: "Eurothemis",
    label: "Eurothemis",
  },
  {
    value: "EVC Advocaten",
    label: "EVC Advocaten",
  },
  {
    value: "Everest Law",
    label: "Everest Law",
  },
  {
    value: "Eversheds Sutherland",
    label: "Eversheds Sutherland",
  },
  {
    value: "Exelia",
    label: "Exelia",
  },
  {
    value: "EY Law",
    label: "EY Law",
  },
  {
    value: "Faber Inter Law",
    label: "Faber Inter Law",
  },
  {
    value: "Faros",
    label: "Faros",
  },
  {
    value: "Fidal Belgium",
    label: "Fidal Belgium",
  },
  {
    value: "Fidas Law",
    label: "Fidas Law",
  },
  {
    value: "Fieldfisher",
    label: "Fieldfisher",
  },
  {
    value: "Fierens Law",
    label: "Fierens Law",
  },
  {
    value: "Flinn Law",
    label: "Flinn Law",
  },
  {
    value: "Foley & Lardner LLP",
    label: "Foley & Lardner LLP",
  },
  {
    value: "Forum Advocaten",
    label: "Forum Advocaten",
  },
  {
    value: "Four & Five Law",
    label: "Four & Five Law",
  },
  {
    value: "Frans Baert & Vennoten",
    label: "Frans Baert & Vennoten",
  },
  {
    value: "Fransen Luyten",
    label: "Fransen Luyten",
  },
  {
    value: "FratiniVergano",
    label: "FratiniVergano",
  },
  {
    value: "Freshfields Bruckhaus Deringer",
    label: "Freshfields Bruckhaus Deringer",
  },
  {
    value: "Gallus",
    label: "Gallus",
  },
  {
    value: "Garrigues",
    label: "Garrigues",
  },
  {
    value: "gds Advocaten",
    label: "gds Advocaten",
  },
  {
    value: "Geradin Partners",
    label: "Geradin Partners",
  },
  {
    value: "Gevaco Advocaten",
    label: "Gevaco Advocaten",
  },
  {
    value: "Gevers",
    label: "Gevers",
  },
  {
    value: "Gianni & Origoni",
    label: "Gianni & Origoni",
  },
  {
    value: "Gianni & Origoni",
    label: "Gianni & Origoni",
  },
  {
    value: "Gibson Dunn",
    label: "Gibson Dunn",
  },
  {
    value: "Gide Loyrette Nouel",
    label: "Gide Loyrette Nouel",
  },
  {
    value: "Gleiss Lutz",
    label: "Gleiss Lutz",
  },
  {
    value: "Gómez-Acebo & Pombo",
    label: "Gómez-Acebo & Pombo",
  },
  {
    value: "Goodwin",
    label: "Goodwin",
  },
  {
    value: "Gowling WLG LLP",
    label: "Gowling WLG LLP",
  },
  {
    value: "GSJ advocaten",
    label: "GSJ advocaten",
  },
  {
    value: "GvW",
    label: "GvW",
  },
  {
    value: "H&K - Heilporn & Kadaner",
    label: "H&K - Heilporn & Kadaner",
  },
  {
    value: "Hanotiau & van den Berg",
    label: "Hanotiau & van den Berg",
  },
  {
    value: "Harneys Lux",
    label: "Harneys Lux",
  },
  {
    value: "Harvest - Business law firm",
    label: "Harvest - Business law firm",
  },
  {
    value: "Hengeler Mueller",
    label: "Hengeler Mueller",
  },
  {
    value: "Henry & Mersch",
    label: "Henry & Mersch",
  },
  {
    value: "Herbert Smith Freehills",
    label: "Herbert Smith Freehills",
  },
  {
    value: "HFW",
    label: "HFW",
  },
  {
    value: "Hirsch & Vanhaelst",
    label: "Hirsch & Vanhaelst",
  },
  {
    value: "Hogan Lovells",
    label: "Hogan Lovells",
  },
  {
    value: "Houthoff",
    label: "Houthoff",
  },
  {
    value: "HOYNG ROKH MONEGIER",
    label: "HOYNG ROKH MONEGIER",
  },
  {
    value: "HSP",
    label: "HSP",
  },
  {
    value: "Hunton Andrews Kurth",
    label: "Hunton Andrews Kurth",
  },
  {
    value: "Impact Advocaten",
    label: "Impact Advocaten",
  },
  {
    value: "INTAKT Law",
    label: "INTAKT Law",
  },
  {
    value: "Inteo",
    label: "Inteo",
  },
  {
    value: "Intolaw",
    label: "Intolaw",
  },
  {
    value: "Intui",
    label: "Intui",
  },
  {
    value: "IP Porta",
    label: "IP Porta",
  },
  {
    value: "ITL Advocaten",
    label: "ITL Advocaten",
  },
  {
    value: "Iuris",
    label: "Iuris",
  },
  {
    value: "Janson",
    label: "Janson",
  },
  {
    value: "John - John Law",
    label: "John - John Law",
  },
  {
    value: "Jones Day",
    label: "Jones Day",
  },
  {
    value: "K&L Gates",
    label: "K&L Gates",
  },
  {
    value: "Kadrant",
    label: "Kadrant",
  },
  {
    value: "Kapellmann und Partner Rechtsanwälte mbB",
    label: "Kapellmann und Partner Rechtsanwälte mbB",
  },
  {
    value: "Kegels Advocaten",
    label: "Kegels Advocaten",
  },
  {
    value: "Keller & Heckman",
    label: "Keller & Heckman",
  },
  {
    value: "Kennedys",
    label: "Kennedys",
  },
  {
    value: "King & Spalding",
    label: "King & Spalding",
  },
  {
    value: "Kirkland & Ellis International LLP",
    label: "Kirkland & Ellis International LLP",
  },
  {
    value: "koan.law",
    label: "koan.law",
  },
  {
    value: "KPMG Law",
    label: "KPMG Law",
  },
  {
    value: "Krzewinski+Partners",
    label: "Krzewinski+Partners",
  },
  {
    value: "Lallemand Legros & Joyn (LLJ) SC",
    label: "Lallemand Legros & Joyn (LLJ) SC",
  },
  {
    value: "Lamon Law",
    label: "Lamon Law",
  },
  {
    value: "Latham & Watkins",
    label: "Latham & Watkins",
  },
  {
    value: "Laurius",
    label: "Laurius",
  },
  {
    value: "Lauwers Fiscale Advocaten",
    label: "Lauwers Fiscale Advocaten",
  },
  {
    value: "Law Right",
    label: "Law Right",
  },
  {
    value: "Lawtree Advocaten",
    label: "Lawtree Advocaten",
  },
  {
    value: "LDR Advocaten in Omgevingsrecht",
    label: "LDR Advocaten in Omgevingsrecht",
  },
  {
    value: "Lebutte & Partners",
    label: "Lebutte & Partners",
  },
  {
    value: "Leeward",
    label: "Leeward",
  },
  {
    value: "Legacity",
    label: "Legacity",
  },
  {
    value: "Lexial",
    label: "Lexial",
  },
  {
    value: "Lexing",
    label: "Lexing",
  },
  {
    value: "LexLau",
    label: "LexLau",
  },
  {
    value: "Lexmind",
    label: "Lexmind",
  },
  {
    value: "Liberius",
    label: "Liberius",
  },
  {
    value: "Liedekerke",
    label: "Liedekerke",
  },
  {
    value: "LIME Law",
    label: "LIME Law",
  },
  {
    value: "Linklaters",
    label: "Linklaters",
  },
  {
    value: "Linklaters",
    label: "Linklaters",
  },
  {
    value: "LMBD",
    label: "LMBD",
  },
  {
    value: "LMDS",
    label: "LMDS",
  },
  {
    value: "Locke Lord",
    label: "Locke Lord",
  },
  {
    value: "Loyens & Loeff",
    label: "Loyens & Loeff",
  },
  {
    value: "Loyens & Loeff",
    label: "Loyens & Loeff",
  },
  {
    value: "Luther",
    label: "Luther",
  },
  {
    value: "Lydian",
    label: "Lydian",
  },
  {
    value: "Mannheimer Swartling",
    label: "Mannheimer Swartling",
  },
  {
    value: "Markato law",
    label: "Markato law",
  },
  {
    value: "Marlex",
    label: "Marlex",
  },
  {
    value: "Matray, Matray & Hallet",
    label: "Matray, Matray & Hallet",
  },
  {
    value: "Mayer Brown",
    label: "Mayer Brown",
  },
  {
    value: "McDermott Will & Emery",
    label: "McDermott Will & Emery",
  },
  {
    value: "MCW Legal",
    label: "MCW Legal",
  },
  {
    value: "Miles Legal",
    label: "Miles Legal",
  },
  {
    value: "Modo law",
    label: "Modo law",
  },
  {
    value: "Monard Law",
    label: "Monard Law",
  },
  {
    value: "Moore Law",
    label: "Moore Law",
  },
  {
    value: "Moov.law",
    label: "Moov.law",
  },
  {
    value: "Morgan Lewis",
    label: "Morgan Lewis",
  },
  {
    value: "Morrison & Foerster LLP",
    label: "Morrison & Foerster LLP",
  },
  {
    value: "Mploy",
    label: "Mploy",
  },
  {
    value: "MVVP",
    label: "MVVP",
  },
  {
    value: "Nautadutilh",
    label: "Nautadutilh",
  },
  {
    value: "Nelissen Grade",
    label: "Nelissen Grade",
  },
  {
    value: "Newton",
    label: "Newton",
  },
  {
    value: "Nibelle & Partners",
    label: "Nibelle & Partners",
  },
  {
    value: "Noerr PartGmbB",
    label: "Noerr PartGmbB",
  },
  {
    value: "Norton Rose Fulbright Bxl",
    label: "Norton Rose Fulbright Bxl",
  },
  {
    value: "Novalis",
    label: "Novalis",
  },
  {
    value: "OAK Law firm",
    label: "OAK Law firm",
  },
  {
    value: "Odigo",
    label: "Odigo",
  },
  {
    value: "Olislaegers & De Creus",
    label: "Olislaegers & De Creus",
  },
  {
    value: "O'Melveny & Myers",
    label: "O'Melveny & Myers",
  },
  {
    value: "Opdebeeck, De Groote & Matthys",
    label: "Opdebeeck, De Groote & Matthys",
  },
  {
    value: "Orrick",
    label: "Orrick",
  },
  {
    value: "Osborne Clarke",
    label: "Osborne Clarke",
  },
  {
    value: "Paul Hastings",
    label: "Paul Hastings",
  },
  {
    value: "Peak Legal",
    label: "Peak Legal",
  },
  {
    value: "Petillion",
    label: "Petillion",
  },
  {
    value: "Petillion",
    label: "Petillion",
  },
  {
    value: "Philippe & Partners",
    label: "Philippe & Partners",
  },
  {
    value: "Pierstone",
    label: "Pierstone",
  },
  {
    value: "Pragma Advocaten",
    label: "Pragma Advocaten",
  },
  {
    value: "Praxislaw",
    label: "Praxislaw",
  },
  {
    value: "Prioux Culot + Partners",
    label: "Prioux Culot + Partners",
  },
  {
    value: "Publius",
    label: "Publius",
  },
  {
    value: "PwC Legal",
    label: "PwC Legal",
  },
  {
    value: "Quinn Emanuel trial lawyers",
    label: "Quinn Emanuel trial lawyers",
  },
  {
    value: "Quinz",
    label: "Quinz",
  },
  {
    value: "Quorum Law",
    label: "Quorum Law",
  },
  {
    value: "Racine",
    label: "Racine",
  },
  {
    value: "Rawlings Giles",
    label: "Rawlings Giles",
  },
  {
    value: "REDEKER SELLNER DAHS Rechtsanwälte",
    label: "REDEKER SELLNER DAHS Rechtsanwälte",
  },
  {
    value: "Reed Smith",
    label: "Reed Smith",
  },
  {
    value: "RELIANCE | Littler",
    label: "RELIANCE | Littler",
  },
  {
    value: "resolut.law",
    label: "resolut.law",
  },
  {
    value: "Reyns Advocaten",
    label: "Reyns Advocaten",
  },
  {
    value: "Roosendaal Keyzer",
    label: "Roosendaal Keyzer",
  },
  {
    value: "Schoenherr",
    label: "Schoenherr",
  },
  {
    value: "Schoups",
    label: "Schoups",
  },
  {
    value: "Schuermans advocaten",
    label: "Schuermans advocaten",
  },
  {
    value: "Seeds of Law",
    label: "Seeds of Law",
  },
  {
    value: "Shape Law Firm",
    label: "Shape Law Firm",
  },
  {
    value: "Shearman & Sterling",
    label: "Shearman & Sterling",
  },
  {
    value: "Sheppard Mullin",
    label: "Sheppard Mullin",
  },
  {
    value: "Shoosmiths",
    label: "Shoosmiths",
  },
  {
    value: "Sidley",
    label: "Sidley",
  },
  {
    value: "Simmons & Simmons",
    label: "Simmons & Simmons",
  },
  {
    value: "Simont Braun",
    label: "Simont Braun",
  },
  {
    value: "Sirius Legal",
    label: "Sirius Legal",
  },
  {
    value: "Skadden",
    label: "Skadden",
  },
  {
    value: "Slaughter and May",
    label: "Slaughter and May",
  },
  {
    value: "Solis Law Firm",
    label: "Solis Law Firm",
  },
  {
    value: "Solutio Lawyers",
    label: "Solutio Lawyers",
  },
  {
    value: "Sotra",
    label: "Sotra",
  },
  {
    value: "Spencer West",
    label: "Spencer West",
  },
  {
    value: "SQ",
    label: "SQ",
  },
  {
    value: "Squire Patton Boggs",
    label: "Squire Patton Boggs",
  },
  {
    value: "Stappers Advocaten",
    label: "Stappers Advocaten",
  },
  {
    value: "Steptoe",
    label: "Steptoe",
  },
  {
    value: "Stibbe",
    label: "Stibbe",
  },
  {
    value: "Strelia",
    label: "Strelia",
  },
  {
    value: "Struyven Advocaten",
    label: "Struyven Advocaten",
  },
  {
    value: "SZA Schilling, Zutt & Anschütz",
    label: "SZA Schilling, Zutt & Anschütz",
  },
  {
    value: "taxandria advocatenkantoor",
    label: "taxandria advocatenkantoor",
  },
  {
    value: "Taxpatria",
    label: "Taxpatria",
  },
  {
    value: "TaylorWessing",
    label: "TaylorWessing",
  },
  {
    value: "Tender Law",
    label: "Tender Law",
  },
  {
    value: "Tetra Law",
    label: "Tetra Law",
  },
  {
    value: "Thales",
    label: "Thales",
  },
  {
    value: "Tiberghien",
    label: "Tiberghien",
  },
  {
    value: "Tilia Law",
    label: "Tilia Law",
  },
  {
    value: "Timelex",
    label: "Timelex",
  },
  {
    value: "Trialys",
    label: "Trialys",
  },
  {
    value: "Tuerlinckx Tax Lawyers",
    label: "Tuerlinckx Tax Lawyers",
  },
  {
    value: "UGGC Avocats",
    label: "UGGC Avocats",
  },
  {
    value: "Uyttendaele Gérard Kennes et Associés",
    label: "Uyttendaele Gérard Kennes et Associés",
  },
  {
    value: "Van Alsenoy & Partners",
    label: "Van Alsenoy & Partners",
  },
  {
    value: "Van Bael & Bellis",
    label: "Van Bael & Bellis",
  },
  {
    value: "van Cutsem Wittamer Marnef & Partners",
    label: "van Cutsem Wittamer Marnef & Partners",
  },
  {
    value: "van den Berg & Partners",
    label: "van den Berg & Partners",
  },
  {
    value: "Van Innis & Delarue",
    label: "Van Innis & Delarue",
  },
  {
    value: "Van Olmen & Wynant",
    label: "Van Olmen & Wynant",
  },
  {
    value: "Van Steenbrugge & Partners (VSA)",
    label: "Van Steenbrugge & Partners (VSA)",
  },
  {
    value: "Vanderstappen - Robert",
    label: "Vanderstappen - Robert",
  },
  {
    value: "Vectis",
    label: "Vectis",
  },
  {
    value: "Verhaegen Walravens",
    label: "Verhaegen Walravens",
  },
  {
    value: "VVGB Law",
    label: "VVGB Law",
  },
  {
    value: "Wery Legal",
    label: "Wery Legal",
  },
  {
    value: "White & Case LLP",
    label: "White & Case LLP",
  },
  {
    value: "Willkie Farr & Gallagher LLP",
    label: "Willkie Farr & Gallagher LLP",
  },
  {
    value: "Wilmerhale",
    label: "Wilmerhale",
  },
  {
    value: "Wilson Sonsini Goodrich & Rosati",
    label: "Wilson Sonsini Goodrich & Rosati",
  },
  {
    value: "Winston & Strawn",
    label: "Winston & Strawn",
  },
  {
    value: "Xirius",
    label: "Xirius",
  },
  {
    value: "Yelaw",
    label: "Yelaw",
  },
  {
    value: "Younity",
    label: "Younity",
  },
];

export const experienceOptions = [
  { value: "0-1 years", label: "0-1 years" },
  { value: "1-3 years", label: "1-3 years" },
  { value: "3-5 years", label: "3-5 years" },
  { value: "5-7 years", label: "5-7 years" },
];

export const salaryRangeOptions = [
  {
    value: "Prefer not to disclose",
    label: "Prefer not to disclose",
  },
  {
    value: "0 – 30.000 EUR",
    label: "0 – 30.000 EUR",
  },
  {
    value: "30.000 – 40.000 EUR",
    label: "30.000 – 40.000 EUR",
  },
  {
    value: "40.000 – 50.000 EUR",
    label: "40.000 – 50.000 EUR",
  },
  {
    value: "50.000 – 75.000 EUR",
    label: "50.000 – 75.000 EUR",
  },
  {
    value: "75.000 – 100.000 EUR",
    label: "75.000 – 100.000 EUR",
  },
  {
    value: "100.000 – 130.000 EUR",
    label: "100.000 – 130.000 EUR",
  },
  {
    value: "130.000 – 150.000 EUR",
    label: "130.000 – 150.000 EUR",
  },
  {
    value: "150.000 – 175.000 EUR",
    label: "150.000 – 175.000 EUR",
  },
  {
    value: "175.000 – 200.000 EUR",
    label: "175.000 – 200.000 EUR",
  },
  {
    value: "200.000 – 250.000 EUR",
    label: "200.000 – 250.000 EUR",
  },
  {
    value: "250K+ EUR",
    label: "250K+ EUR",
  },
];

export const llmOptions = [
  {
    value: "Harvard Law School",
    label: "Harvard Law School",
  },
  {
    value: "College of Europe",
    label: "College of Europe",
  },
  {
    value: "Yale Law School",
    label: "Yale Law School",
  },
  {
    value: "Stanford Law School",
    label: "Stanford Law School",
  },
  {
    value: "University of Oxford",
    label: "University of Oxford",
  },
  {
    value: "University of Cambridge",
    label: "University of Cambridge",
  },
  {
    value: "London School of Economics and Political Science (LSE)",
    label: "London School of Economics and Political Science (LSE)",
  },
  {
    value: "University of Melbourne",
    label: "University of Melbourne",
  },
  {
    value: "National University of Singapore (NUS) Faculty of Law",
    label: "National University of Singapore (NUS) Faculty of Law",
  },
  {
    value: "University of Toronto Faculty of Law",
    label: "University of Toronto Faculty of Law",
  },
  {
    value: "McGill University Faculty of Law",
    label: "McGill University Faculty of Law",
  },
  {
    value: "University of Chicago Law School",
    label: "University of Chicago Law School",
  },
  {
    value: "Columbia Law School",
    label: "Columbia Law School",
  },
  {
    value: "Georgetown University Law Center",
    label: "Georgetown University Law Center",
  },
  {
    value: "University of California, Berkeley (UC Berkeley) School of Law",
    label: "University of California, Berkeley (UC Berkeley) School of Law",
  },
  {
    value: "University of Virginia School of Law",
    label: "University of Virginia School of Law",
  },
  {
    value: "New York University (NYU) School of Law",
    label: "New York University (NYU) School of Law",
  },
  {
    value: "University of Pennsylvania Carey Law School",
    label: "University of Pennsylvania Carey Law School",
  },
  {
    value: "King's College London",
    label: "King's College London",
  },
  {
    value: "University of Edinburgh School of Law",
    label: "University of Edinburgh School of Law",
  },
  {
    value: "Bucerius Law School",
    label: "Bucerius Law School",
  },
  {
    value: "Sorbonne Law School",
    label: "Sorbonne Law School",
  },
  {
    value: "Humboldt University of Berlin",
    label: "Humboldt University of Berlin",
  },
  {
    value: "University of Amsterdam Faculty of Law",
    label: "University of Amsterdam Faculty of Law",
  },
  {
    value: "University of Zurich Faculty of Law",
    label: "University of Zurich Faculty of Law",
  },
  {
    value: "Stockholm University Faculty of Law",
    label: "Stockholm University Faculty of Law",
  },
  {
    value: "University of Cape Town Faculty of Law",
    label: "University of Cape Town Faculty of Law",
  },
  {
    value: "University of São Paulo Faculty of Law",
    label: "University of São Paulo Faculty of Law",
  },
  {
    value: "Peking University School of Transnational Law",
    label: "Peking University School of Transnational Law",
  },
  {
    value: "Seoul National University School of Law",
    label: "Seoul National University School of Law",
  },
  {
    value: "Hebrew University of Jerusalem Faculty of Law",
    label: "Hebrew University of Jerusalem Faculty of Law",
  },
  {
    value: "University of Hong Kong Faculty of Law",
    label: "University of Hong Kong Faculty of Law",
  },
  {
    value: "University of Tokyo Graduate Schools for Law and Politics",
    label: "University of Tokyo Graduate Schools for Law and Politics",
  },
  {
    value: "National Taiwan University College of Law",
    label: "National Taiwan University College of Law",
  },
  {
    value: "University of Sydney Law School",
    label: "University of Sydney Law School",
  },
  {
    value: "University of Queensland TC Beirne School of Law",
    label: "University of Queensland TC Beirne School of Law",
  },
  {
    value: "University of Auckland Faculty of Law",
    label: "University of Auckland Faculty of Law",
  },
  {
    value: "University of Melbourne Law School",
    label: "University of Melbourne Law School",
  },
  {
    value: "University of New South Wales (UNSW) Faculty of Law",
    label: "University of New South Wales (UNSW) Faculty of Law",
  },
  {
    value: "Monash University Faculty of Law",
    label: "Monash University Faculty of Law",
  },
  {
    value: "University of British Columbia Peter A. Allard School of Law",
    label: "University of British Columbia Peter A. Allard School of Law",
  },
  {
    value: "University of Alberta Faculty of Law",
    label: "University of Alberta Faculty of Law",
  },
  {
    value: "University of Victoria Faculty of Law",
    label: "University of Victoria Faculty of Law",
  },
  {
    value: "Dalhousie University Schulich School of Law",
    label: "Dalhousie University Schulich School of Law",
  },
  {
    value: "Université de Montréal Faculté de droit",
    label: "Université de Montréal Faculté de droit",
  },
  {
    value: "University of Calgary Faculty of Law",
    label: "University of Calgary Faculty of Law",
  },
  {
    value: "Queen's University Faculty of Law",
    label: "Queen's University Faculty of Law",
  },
  {
    value: "University of Ottawa Faculty of Law",
    label: "University of Ottawa Faculty of Law",
  },
  {
    value: "University of Saskatchewan College of Law",
    label: "University of Saskatchewan College of Law",
  },
  {
    value: "University of Western Ontario Faculty of Law",
    label: "University of Western Ontario Faculty of Law",
  },
  {
    value: "University of Windsor Faculty of Law",
    label: "University of Windsor Faculty of Law",
  },
  {
    value: "Ryerson University Faculty of Law",
    label: "Ryerson University Faculty of Law",
  },
  {
    value: "All India Institute of Medical Sciences (AIIMS)",
    label: "All India Institute of Medical Sciences (AIIMS)",
  },
  {
    value: "National Law School of India University (NLSIU)",
    label: "National Law School of India University (NLSIU)",
  },
  {
    value: "NALSAR University of Law",
    label: "NALSAR University of Law",
  },
  {
    value: "West Bengal National University of Juridical Sciences (NUJS)",
    label: "West Bengal National University of Juridical Sciences (NUJS)",
  },
  {
    value: "University of Delhi Faculty of Law",
    label: "University of Delhi Faculty of Law",
  },
  {
    value: "University of Mumbai Law Academy",
    label: "University of Mumbai Law Academy",
  },
  {
    value: "Gujarat National Law University (GNLU)",
    label: "Gujarat National Law University (GNLU)",
  },
  {
    value: "National University of Advanced Legal Studies (NUALS)",
    label: "National University of Advanced Legal Studies (NUALS)",
  },
  {
    value: "Hidayatullah National Law University (HNLU)",
    label: "Hidayatullah National Law University (HNLU)",
  },
  {
    value: "Jamia Millia Islamia Faculty of Law",
    label: "Jamia Millia Islamia Faculty of Law",
  },
  {
    value: "Amity Law School",
    label: "Amity Law School",
  },
  {
    value: "Jindal Global Law School",
    label: "Jindal Global Law School",
  },
  {
    value: "National Law Institute University (NLIU)",
    label: "National Law Institute University (NLIU)",
  },
  {
    value: "Symbiosis Law School",
    label: "Symbiosis Law School",
  },
  {
    value: "Singapore Management University (SMU) School of Law",
    label: "Singapore Management University (SMU) School of Law",
  },
  {
    value: "Nanyang Technological University (NTU) School of Law",
    label: "Nanyang Technological University (NTU) School of Law",
  },
  {
    value: "City University of Hong Kong School of Law",
    label: "City University of Hong Kong School of Law",
  },
  {
    value: "Chinese University of Hong Kong Faculty of Law",
    label: "Chinese University of Hong Kong Faculty of Law",
  },
  {
    value: "University of Macau Faculty of Law",
    label: "University of Macau Faculty of Law",
  },
  {
    value: "Hong Kong Baptist University School of Business and Law",
    label: "Hong Kong Baptist University School of Business and Law",
  },
  {
    value: "Renmin University of China Law School",
    label: "Renmin University of China Law School",
  },
  {
    value: "Tsinghua University School of Law",
    label: "Tsinghua University School of Law",
  },
  {
    value: "Fudan University Law School",
    label: "Fudan University Law School",
  },
  {
    value: "Zhejiang University Guanghua Law School",
    label: "Zhejiang University Guanghua Law School",
  },
  {
    value: "Waseda University School of Law",
    label: "Waseda University School of Law",
  },
  {
    value: "Keio University Law School",
    label: "Keio University Law School",
  },
  {
    value: "Sophia University Faculty of Law",
    label: "Sophia University Faculty of Law",
  },
  {
    value: "Hitotsubashi University Graduate School of Law",
    label: "Hitotsubashi University Graduate School of Law",
  },
  {
    value: "Korea University School of Law",
    label: "Korea University School of Law",
  },
  {
    value: "Yonsei University School of Law",
    label: "Yonsei University School of Law",
  },
  {
    value: "Sungkyunkwan University School of Law",
    label: "Sungkyunkwan University School of Law",
  },
  {
    value: "Hanyang University Law School",
    label: "Hanyang University Law School",
  },
  {
    value: "Ewha Womans University School of Law",
    label: "Ewha Womans University School of Law",
  },
  {
    value: "Ajou University College of Law",
    label: "Ajou University College of Law",
  },
  {
    value: "Pusan National University School of Law",
    label: "Pusan National University School of Law",
  },
  {
    value: "Tel Aviv University Buchmann Faculty of Law",
    label: "Tel Aviv University Buchmann Faculty of Law",
  },
  {
    value: "Interdisciplinary Center Herzliya (IDC) Radzyner Law School",
    label: "Interdisciplinary Center Herzliya (IDC) Radzyner Law School",
  },
  {
    value: "University of Haifa Faculty of Law",
    label: "University of Haifa Faculty of Law",
  },
  {
    value:
      "University of Tel Aviv S. Horowitz Institute for Intellectual Property",
    label:
      "University of Tel Aviv S. Horowitz Institute for Intellectual Property",
  },
  {
    value: "University of Johannesburg Faculty of Law",
    label: "University of Johannesburg Faculty of Law",
  },
  {
    value: "University of Pretoria Faculty of Law",
    label: "University of Pretoria Faculty of Law",
  },
  {
    value: "University of the Witwatersrand Oliver Schreiner School of Law",
    label: "University of the Witwatersrand Oliver Schreiner School of Law",
  },
  {
    value: "University of Helsinki Faculty of Law",
    label: "University of Helsinki Faculty of Law",
  },
  {
    value: "University of Oslo Faculty of Law",
    label: "University of Oslo Faculty of Law",
  },
  {
    value: "University of Copenhagen Faculty of Law",
    label: "University of Copenhagen Faculty of Law",
  },
  {
    value: "University of Stockholm Faculty of Law",
    label: "University of Stockholm Faculty of Law",
  },
  {
    value: "University of Vienna Faculty of Law",
    label: "University of Vienna Faculty of Law",
  },
  {
    value: "University of Geneva Faculty of Law",
    label: "University of Geneva Faculty of Law",
  },
  {
    value: "University of Lausanne Faculty of Law",
    label: "University of Lausanne Faculty of Law",
  },
  {
    value: "University of St. Gallen Law School",
    label: "University of St. Gallen Law School",
  },
  {
    value: "University of Leuven Faculty of Law",
    label: "University of Leuven Faculty of Law",
  },
  {
    value: "Ghent University Faculty of Law",
    label: "Ghent University Faculty of Law",
  },
  {
    value: "Catholic University of Leuven Faculty of Law",
    label: "Catholic University of Leuven Faculty of Law",
  },
  {
    value: "University of Barcelona Faculty of Law",
    label: "University of Barcelona Faculty of Law",
  },
  {
    value: "University of Madrid Faculty of Law",
    label: "University of Madrid Faculty of Law",
  },
  {
    value: "Autonomous University of Barcelona School of Law",
    label: "Autonomous University of Barcelona School of Law",
  },
  {
    value: "Complutense University of Madrid Faculty of Law",
    label: "Complutense University of Madrid Faculty of Law",
  },
  {
    value: "University of Lisbon Faculty of Law",
    label: "University of Lisbon Faculty of Law",
  },
  {
    value: "University of Porto Faculty of Law",
    label: "University of Porto Faculty of Law",
  },
  {
    value: "University of Warsaw Faculty of Law and Administration",
    label: "University of Warsaw Faculty of Law and Administration",
  },
  {
    value: "Jagiellonian University Faculty of Law and Administration",
    label: "Jagiellonian University Faculty of Law and Administration",
  },
  {
    value: "University of Bucharest Faculty of Law",
    label: "University of Bucharest Faculty of Law",
  },
  {
    value: "Charles University Faculty of Law",
    label: "Charles University Faculty of Law",
  },
  {
    value: "University of Ljubljana Faculty of Law",
    label: "University of Ljubljana Faculty of Law",
  },
  {
    value: "University of Zagreb Faculty of Law",
    label: "University of Zagreb Faculty of Law",
  },
  {
    value: "University of Belgrade Faculty of Law",
    label: "University of Belgrade Faculty of Law",
  },
  {
    value: "University of Sofia Faculty of Law",
    label: "University of Sofia Faculty of Law",
  },
  {
    value: "University of Athens Faculty of Law",
    label: "University of Athens Faculty of Law",
  },
  {
    value: "University of Cyprus Faculty of Law",
    label: "University of Cyprus Faculty of Law",
  },
  {
    value: "Istanbul University Faculty of Law",
    label: "Istanbul University Faculty of Law",
  },
  {
    value: "Koç University Law School",
    label: "Koç University Law School",
  },
  {
    value: "Middle East Technical University Faculty of Law",
    label: "Middle East Technical University Faculty of Law",
  },
  {
    value: "American University of Beirut Faculty of Law",
    label: "American University of Beirut Faculty of Law",
  },
  {
    value: "Cairo University Faculty of Law",
    label: "Cairo University Faculty of Law",
  },
  {
    value: "University of Ghana School of Law",
    label: "University of Ghana School of Law",
  },
  {
    value: "University of Nairobi School of Law",
    label: "University of Nairobi School of Law",
  },
  {
    value: "University of Dar es Salaam School of Law",
    label: "University of Dar es Salaam School of Law",
  },
  {
    value: "Makerere University School of Law",
    label: "Makerere University School of Law",
  },
  {
    value: "Strathmore Law School",
    label: "Strathmore Law School",
  },
  {
    value: "University of the Western Cape Faculty of Law",
    label: "University of the Western Cape Faculty of Law",
  },
  {
    value: "Addis Ababa University Faculty of Law",
    label: "Addis Ababa University Faculty of Law",
  },
  {
    value: "University of Lagos Faculty of Law",
    label: "University of Lagos Faculty of Law",
  },
  {
    value: "University of Ibadan Faculty of Law",
    label: "University of Ibadan Faculty of Law",
  },
  {
    value: "University of Cape Coast Faculty of Law",
    label: "University of Cape Coast Faculty of Law",
  },
  {
    value: "University of Namibia Faculty of Law",
    label: "University of Namibia Faculty of Law",
  },
  {
    value: "University of Mauritius Faculty of Law",
    label: "University of Mauritius Faculty of Law",
  },
  {
    value: "University of the West Indies Faculty of Law",
    label: "University of the West Indies Faculty of Law",
  },
  {
    value: "National Law University, Delhi",
    label: "National Law University, Delhi",
  },
  {
    value: "National Law University, Jodhpur",
    label: "National Law University, Jodhpur",
  },
  {
    value: "National University of Juridical Sciences (NUJS)",
    label: "National University of Juridical Sciences (NUJS)",
  },
  {
    value: "Faculty of Law, Banaras Hindu University",
    label: "Faculty of Law, Banaras Hindu University",
  },
  {
    value: "Aligarh Muslim University Faculty of Law",
    label: "Aligarh Muslim University Faculty of Law",
  },
  {
    value: "University of Calcutta Department of Law",
    label: "University of Calcutta Department of Law",
  },
  {
    value: "The Chinese University of Hong Kong Faculty of Law",
    label: "The Chinese University of Hong Kong Faculty of Law",
  },
  {
    value: "Hong Kong University of Science and Technology (HKUST)",
    label: "Hong Kong University of Science and Technology (HKUST)",
  },
  {
    value: "University of Tsukuba College of Law and Economics",
    label: "University of Tsukuba College of Law and Economics",
  },
  {
    value: "Handong International Law School",
    label: "Handong International Law School",
  },
  {
    value: "Bar-Ilan University Faculty of Law",
    label: "Bar-Ilan University Faculty of Law",
  },
  {
    value: "Bilkent University Faculty of Law",
    label: "Bilkent University Faculty of Law",
  },
  {
    value: "Istanbul Bilgi University Faculty of Law",
    label: "Istanbul Bilgi University Faculty of Law",
  },
  {
    value: "Uppsala University Faculty of Law",
    label: "Uppsala University Faculty of Law",
  },
  {
    value: "University of Turin Faculty of Law",
    label: "University of Turin Faculty of Law",
  },
  {
    value: "University of Bologna School of Law",
    label: "University of Bologna School of Law",
  },
  {
    value: "University of Milan Faculty of Law",
    label: "University of Milan Faculty of Law",
  },
  {
    value: "University of Rome 'La Sapienza' Faculty of Law",
    label: "University of Rome 'La Sapienza' Faculty of Law",
  },
  {
    value: "University of Salamanca Faculty of Law",
    label: "University of Salamanca Faculty of Law",
  },
  {
    value: "University of Granada Faculty of Law",
    label: "University of Granada Faculty of Law",
  },
  {
    value: "University of Coimbra Faculty of Law",
    label: "University of Coimbra Faculty of Law",
  },
  {
    value: "University of Heidelberg Faculty of Law",
    label: "University of Heidelberg Faculty of Law",
  },
  {
    value: "Ludwig Maximilian University of Munich Faculty of Law",
    label: "Ludwig Maximilian University of Munich Faculty of Law",
  },
  {
    value: "Humboldt University of Berlin Faculty of Law",
    label: "Humboldt University of Berlin Faculty of Law",
  },
  {
    value: "Free University of Berlin Law School",
    label: "Free University of Berlin Law School",
  },
  {
    value: "University of Goettingen Faculty of Law",
    label: "University of Goettingen Faculty of Law",
  },
  {
    value: "University of Cologne Faculty of Law",
    label: "University of Cologne Faculty of Law",
  },
  {
    value: "University of Maastricht Faculty of Law",
    label: "University of Maastricht Faculty of Law",
  },
  {
    value: "VU University Amsterdam Faculty of Law",
    label: "VU University Amsterdam Faculty of Law",
  },
  {
    value: "Erasmus University Rotterdam Erasmus School of Law",
    label: "Erasmus University Rotterdam Erasmus School of Law",
  },
  {
    value: "University of Groningen Faculty of Law",
    label: "University of Groningen Faculty of Law",
  },
  {
    value: "University of Leiden Faculty of Law",
    label: "University of Leiden Faculty of Law",
  },
  {
    value: "University of Utrecht School of Law",
    label: "University of Utrecht School of Law",
  },
  {
    value: "University of Antwerp Faculty of Law",
    label: "University of Antwerp Faculty of Law",
  },
  {
    value: "Tilburg University Tilburg Law School",
    label: "Tilburg University Tilburg Law School",
  },
  {
    value: "University of Aberdeen School of Law",
    label: "University of Aberdeen School of Law",
  },
  {
    value: "University of Bristol Law School",
    label: "University of Bristol Law School",
  },
  {
    value: "University of Birmingham Law School",
    label: "University of Birmingham Law School",
  },
  {
    value: "University of Manchester School of Law",
    label: "University of Manchester School of Law",
  },
  {
    value: "Queen Mary University of London School of Law",
    label: "Queen Mary University of London School of Law",
  },
  {
    value: "University of Nottingham School of Law",
    label: "University of Nottingham School of Law",
  },
  {
    value: "University of Southampton Law School",
    label: "University of Southampton Law School",
  },
  {
    value: "University of Glasgow School of Law",
    label: "University of Glasgow School of Law",
  },
  {
    value: "King's College London Dickson Poon School of Law",
    label: "King's College London Dickson Poon School of Law",
  },
  {
    value: "University of Liverpool Law School",
    label: "University of Liverpool Law School",
  },
  {
    value: "University of Leicester School of Law",
    label: "University of Leicester School of Law",
  },
  {
    value: "University of East Anglia Law School",
    label: "University of East Anglia Law School",
  },
  {
    value: "University of Exeter Law School",
    label: "University of Exeter Law School",
  },
  {
    value: "University of Essex School of Law",
    label: "University of Essex School of Law",
  },
  {
    value: "University of Hull Law School",
    label: "University of Hull Law School",
  },
  {
    value: "University of Kent Law School",
    label: "University of Kent Law School",
  },
  {
    value: "University of Sussex School of Law, Politics and Sociology",
    label: "University of Sussex School of Law, Politics and Sociology",
  },
  {
    value: "University of Warwick School of Law",
    label: "University of Warwick School of Law",
  },
  {
    value: "University of York York Law School",
    label: "University of York York Law School",
  },
  {
    value: "Radboud University Faculty of Law",
    label: "Radboud University Faculty of Law",
  },
  {
    value: "University of Nijmegen Faculty of Law",
    label: "University of Nijmegen Faculty of Law",
  },
  {
    value: "University of Tilburg Tilburg Law School",
    label: "University of Tilburg Tilburg Law School",
  },
  {
    value: "Utrecht University School of Law",
    label: "Utrecht University School of Law",
  },
  {
    value: "University of Graz Faculty of Law",
    label: "University of Graz Faculty of Law",
  },
  {
    value: "University of Innsbruck Faculty of Law",
    label: "University of Innsbruck Faculty of Law",
  },
  {
    value: "University of Salzburg Faculty of Law",
    label: "University of Salzburg Faculty of Law",
  },
  {
    value: "University of Lucerne Faculty of Law",
    label: "University of Lucerne Faculty of Law",
  },
  {
    value: "University of Basel Faculty of Law",
    label: "University of Basel Faculty of Law",
  },
  {
    value: "University of Neuchâtel Faculty of Law",
    label: "University of Neuchâtel Faculty of Law",
  },
  {
    value: "University of Fribourg Faculty of Law",
    label: "University of Fribourg Faculty of Law",
  },
  {
    value: "University of Bern Faculty of Law",
    label: "University of Bern Faculty of Law",
  },
  {
    value: "KU Leuven Faculty of Law",
    label: "KU Leuven Faculty of Law",
  },
  {
    value: "Vrije Universiteit Brussel Faculty of Law",
    label: "Vrije Universiteit Brussel Faculty of Law",
  },
  {
    value: "Université Libre de Bruxelles Faculty of Law",
    label: "Université Libre de Bruxelles Faculty of Law",
  },
  {
    value: "Université catholique de Louvain Faculty of Law",
    label: "Université catholique de Louvain Faculty of Law",
  },
  {
    value: "University of Liège Faculty of Law",
    label: "University of Liège Faculty of Law",
  },
  {
    value: "University of Mons Faculty of Law",
    label: "University of Mons Faculty of Law",
  },
  {
    value: "University of Luxembourg Faculty of Law, Economics and Finance",
    label: "University of Luxembourg Faculty of Law, Economics and Finance",
  },
  {
    value: "University of Malta Faculty of Laws",
    label: "University of Malta Faculty of Laws",
  },
];

// export const positionOptions = [
//   {
//     value: "Managing Partner",
//     label: "Managing Partner",
//   },
//   {
//     value: "Partner",
//     label: "Partner",
//   },
//   {
//     value: "Associate Attorney",
//     label: "Associate Attorney",
//   },
//   {
//     value: "Senior Counsel",
//     label: "Senior Counsel",
//   },
//   {
//     value: "Junior Associate",
//     label: "Junior Associate",
//   },
//   {
//     value: "Paralegal",
//     label: "Paralegal",
//   },
//   {
//     value: "Legal Secretary",
//     label: "Legal Secretary",
//   },
//   {
//     value: "Legal Assistant",
//     label: "Legal Assistant",
//   },
//   {
//     value: "Law Clerk",
//     label: "Law Clerk",
//   },
//   {
//     value: "Legal Intern",
//     label: "Legal Intern",
//   },
//   {
//     value: "Practice Group Leader",
//     label: "Practice Group Leader",
//   },
//   {
//     value: "Of Counsel",
//     label: "Of Counsel",
//   },
//   {
//     value: "Legal Researcher",
//     label: "Legal Researcher",
//   },
//   {
//     value: "Contract Specialist",
//     label: "Contract Specialist",
//   },
//   {
//     value: "Compliance Officer",
//     label: "Compliance Officer",
//   },
//   {
//     value: "Senior Partner",
//     label: "Senior Partner",
//   },
//   {
//     value: "Junior Partner",
//     label: "Junior Partner",
//   },
//   {
//     value: "Special Counsel",
//     label: "Special Counsel",
//   },
//   {
//     value: "Senior Associate",
//     label: "Senior Associate",
//   },
//   {
//     value: "Junior Counsel",
//     label: "Junior Counsel",
//   },
//   {
//     value: "Litigation Attorney",
//     label: "Litigation Attorney",
//   },
//   {
//     value: "Corporate Attorney",
//     label: "Corporate Attorney",
//   },
//   {
//     value: "Tax Attorney",
//     label: "Tax Attorney",
//   },
//   {
//     value: "Intellectual Property Attorney",
//     label: "Intellectual Property Attorney",
//   },
//   {
//     value: "Real Estate Attorney",
//     label: "Real Estate Attorney",
//   },
//   {
//     value: "Employment Attorney",
//     label: "Employment Attorney",
//   },
//   {
//     value: "Family Law Attorney",
//     label: "Family Law Attorney",
//   },
//   {
//     value: "Immigration Attorney",
//     label: "Immigration Attorney",
//   },
//   {
//     value: "Environmental Attorney",
//     label: "Environmental Attorney",
//   },
//   {
//     value: "Trial Attorney",
//     label: "Trial Attorney",
//   },
// ]
export const positionOptions = [
  {
    value: "Junior Associate (0-3 years) ",
    label: "Junior Associate (0-3 years) ",
  },
  {
    value: "Mid Level (3-5 years)",
    label: "Mid Level (3-5 years)",
  },
  {
    value: " Senior Associate (5-7 years)",
    label: " Senior Associate (5-7 years)",
  },
  {
    value: "All",
    label: "All",
  },
];

export const belgiumCitiesOptions = [
  { value: "Aalst", label: "Aalst" },
  { value: "Aarschot", label: "Aarschot" },
  { value: "Antwerp", label: "Antwerp" },
  { value: "Blankenberge", label: "Blankenberge" },
  { value: "Bruges", label: "Bruges" },
  { value: "Brussels", label: "Brussels" },
  { value: "Charleroi", label: "Charleroi" },
  { value: "Dendermonde", label: "Dendermonde" },
  { value: "Genk", label: "Genk" },
  { value: "Ghent", label: "Ghent" },
  { value: "Hasselt", label: "Hasselt" },
  { value: "Kortrijk", label: "Kortrijk" },
  { value: "Leuven", label: "Leuven" },
  { value: "Liège", label: "Liège" },
  { value: "Mechelen", label: "Mechelen" },
  { value: "Mons", label: "Mons" },
  { value: "Namur", label: "Namur" },
  { value: "Ostend", label: "Ostend" },
  { value: "Roeselare", label: "Roeselare" },
  { value: "Sint-Niklaas", label: "Sint-Niklaas" },
  { value: "Tournai", label: "Tournai" },
  { value: "Sint-Niklaas", label: "Sint-Niklaas" },
  { value: "Tervuren", label: "Tervuren" },
  { value: "Tongeren", label: "Tongeren" },
  { value: "Turnhout", label: "Turnhout" },
  { value: "Veurne", label: "Veurne" },
  { value: "Vilvoorde", label: "Vilvoorde" },
  { value: "Waregem", label: "Waregem" },
  { value: "Wavre", label: "Wavre" },
  { value: "Zaventem", label: "Zaventem" },
  { value: "Aartselaar", label: "Aartselaar" },
  { value: "Arendonk", label: "Arendonk" },
  { value: "Asse", label: "Asse" },
  { value: "Balen", label: "Balen" },
  { value: "Beerse", label: "Beerse" },
  { value: "Berchem", label: "Berchem" },
  { value: "Beringen", label: "Beringen" },
  { value: "Beveren", label: "Beveren" },
  { value: "Bilzen", label: "Bilzen" },
  { value: "Bocholt", label: "Bocholt" },
  { value: "Boechout", label: "Boechout" },
  { value: "Bonheiden", label: "Bonheiden" },
  { value: "Boom", label: "Boom" },
  { value: "Bornem", label: "Bornem" },
  { value: "Borsbeek", label: "Borsbeek" },
  { value: "Boutersem", label: "Boutersem" },
  { value: "Brasschaat", label: "Brasschaat" },
  { value: "Brecht", label: "Brecht" },
  { value: "Buggenhout", label: "Buggenhout" },
  { value: "Diepenbeek", label: "Diepenbeek" },
  { value: "Diest", label: "Diest" },
  { value: "Dilbeek", label: "Dilbeek" },
  { value: "Duffel", label: "Duffel" },
  { value: "Edegem", label: "Edegem" },
  { value: "Ekeren", label: "Ekeren" },
  { value: "Erpe-Mere", label: "Erpe-Mere" },
  { value: "Essen", label: "Essen" },
  { value: "Evergem", label: "Evergem" },
  { value: "Geel", label: "Geel" },
  { value: "Geraardsbergen", label: "Geraardsbergen" },
  { value: "Grimbergen", label: "Grimbergen" },
  { value: "Grobbendonk", label: "Grobbendonk" },
  { value: "Haacht", label: "Haacht" },
  { value: "Halle", label: "Halle" },
  { value: "Hamme", label: "Hamme" },
  { value: "Harelbeke", label: "Harelbeke" },
  { value: "Heist-op-den-Berg", label: "Heist-op-den-Berg" },
  { value: "Hemiksem", label: "Hemiksem" },
  { value: "Herent", label: "Herent" },
  { value: "Herentals", label: "Herentals" },
  { value: "Herenthout", label: "Herenthout" },
  { value: "Herk-de-Stad", label: "Herk-de-Stad" },
  { value: "Herne", label: "Herne" },
  { value: "Herselt", label: "Herselt" },
  { value: "Herzele", label: "Herzele" },
  { value: "Heusden-Zolder", label: "Heusden-Zolder" },
  { value: "Heuvelland", label: "Heuvelland" },
  { value: "Hoegaarden", label: "Hoegaarden" },
  { value: "Hoeilaart", label: "Hoeilaart" },
  { value: "Holsbeek", label: "Holsbeek" },
  { value: "Hooglede", label: "Hooglede" },
  { value: "Hoogstraten", label: "Hoogstraten" },
  { value: "Hove", label: "Hove" },
  { value: "Huldenberg", label: "Huldenberg" },
  { value: "Hulshout", label: "Hulshout" },
  { value: "Ichtegem", label: "Ichtegem" },
  { value: "Ieper", label: "Ieper" },
  { value: "Ingelmunster", label: "Ingelmunster" },
  { value: "Izegem", label: "Izegem" },
  { value: "Kalmthout", label: "Kalmthout" },
  { value: "Kapellen", label: "Kapellen" },
  { value: "Keerbergen", label: "Keerbergen" },
  { value: "Kieldrecht", label: "Kieldrecht" },
  { value: "Kinrooi", label: "Kinrooi" },
  { value: "Knesselare", label: "Knesselare" },
  { value: "Knokke-Heist", label: "Knokke-Heist" },
  { value: "Koekelare", label: "Koekelare" },
  { value: "Koksijde", label: "Koksijde" },
  { value: "Kontich", label: "Kontich" },
  { value: "Kortenberg", label: "Kortenberg" },
  { value: "Kruibeke", label: "Kruibeke" },
  { value: "Kruishoutem", label: "Kruishoutem" },
  { value: "Kuurne", label: "Kuurne" },
  { value: "Laarne", label: "Laarne" },
  { value: "Lanaken", label: "Lanaken" },
  { value: "Laarne", label: "Laarne" },
  { value: "Lanaken", label: "Lanaken" },
  { value: "Landen", label: "Landen" },
  { value: "Lede", label: "Lede" },
  { value: "Ledeberg", label: "Ledeberg" },
  { value: "Ledegem", label: "Ledegem" },
  { value: "Lendelede", label: "Lendelede" },
  { value: "Lennik", label: "Lennik" },
];
