import DOMPurify from "isomorphic-dompurify";

import styles from "./SanitizeString.module.css";

interface ISanitizeStringProps {
  content: string;
  className?: string;
}

const SanitizeString = ({ content, className }: ISanitizeStringProps) => {
  const cleanText = DOMPurify.sanitize(content);

  const cleanerText = cleanText
    .replace(/&lt;/g, "<")
    .replace(/&gt;/g, ">")
    .replace(/&amp;/g, "&")
    .replaceAll("<a", `<a target="_blank"`);

  if (cleanerText.length > 0) {
    return (
      <div
        className={`${styles.sanitizedContent} ${className ? className : ""}`}
        /* eslint-disable-next-line react/no-danger */
        dangerouslySetInnerHTML={{
          __html: cleanerText,
        }}
      />
    );
  }
};

export default SanitizeString;
