import { createContext, useMemo, useState } from "react";

export interface GlobalModalsContextProps {
  matchModalData: null | {};
  setMatchModalData: (data: any) => void;
  firmLikeModalData: null | {};
  setFirmLikeModalData: (data: any) => void;
  recruiterMatchModalData: null | {};
  setRecruiterMatchModalData: (data: any) => void;
  messageModalData: null | {};
  setMessageModalData: (data: any) => void;
  acceptedApplicationModalData: null | {};
  setAcceptedApplicationModalData: (data: any) => void;
}

const defaultProps: GlobalModalsContextProps = {
  matchModalData: null,
  setMatchModalData: () => {},
  firmLikeModalData: null,
  setFirmLikeModalData: () => {},
  recruiterMatchModalData: null,
  setRecruiterMatchModalData: () => {},
  messageModalData: null,
  setMessageModalData: () => {},
  acceptedApplicationModalData: null,
  setAcceptedApplicationModalData: () => {},
};

const GlobalModalsContext =
  createContext<GlobalModalsContextProps>(defaultProps);

type Props = {
  children?: React.ReactNode;
};

const GlobalModalsProvider = ({ children }: Props) => {
  const [matchModalData, setMatchModalData] = useState(null);
  const [firmLikeModalData, setFirmLikeModalData] = useState(null);
  const [recruiterMatchModalData, setRecruiterMatchModalData] = useState(null);
  const [messageModalData, setMessageModalData] = useState(null);
  const [acceptedApplicationModalData, setAcceptedApplicationModalData] =
    useState(null);

  const contextValue = useMemo<GlobalModalsContextProps>(() => {
    return {
      matchModalData,
      setMatchModalData,
      firmLikeModalData,
      setFirmLikeModalData,
      recruiterMatchModalData,
      setRecruiterMatchModalData,
      messageModalData,
      setMessageModalData,
      acceptedApplicationModalData,
      setAcceptedApplicationModalData,
    };
  }, [
    matchModalData,
    messageModalData,
    messageModalData,
    firmLikeModalData,
    recruiterMatchModalData,
    acceptedApplicationModalData,
  ]);

  return (
    <GlobalModalsContext.Provider value={contextValue}>
      {children}
    </GlobalModalsContext.Provider>
  );
};

export default GlobalModalsContext;
export { GlobalModalsProvider };
