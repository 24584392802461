import {
  ChatContainer,
  MainContainer,
  Message,
  MessageInput,
  MessageList,
  MessageSeparator,
} from "@chatscope/chat-ui-kit-react";
import {
  Timestamp,
  collection,
  doc,
  query,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useSelector } from "react-redux";
import { DotLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import { userSelector } from "../../app/userRedux/userSlice";
import { db } from "../../config/firebase";
import { IMAGES } from "../../constants/images.jsx";
import starterMessages from "./starterMessages.js";
import styles from "./Messages.module.css";
import { messageDateDisplay } from "../../utils/dates";
import DOMPurify from "isomorphic-dompurify";

function removeTrailingNbsp(message) {
  while (message.endsWith("&nbsp;")) {
    message = message.slice(0, -6); // Remove the last 6 characters (length of "&nbsp;")
  }
  const cleanMessage = DOMPurify.sanitize(message);
  return cleanMessage;
}

const Messages = ({ conversation, conversationPartner }) => {
  const user = useSelector(userSelector);
  const conversationId = conversation.id;
  const [messages, loadingMessages] = useCollectionData(
    query(collection(db, "conversations", conversationId, "messages"))
  );

  const [processedMessages, setProcessedMessages] = useState([]);

  useEffect(() => {
    const unreadMessages =
      messages?.filter(
        (message) => message.read === false && message.sentBy !== user.uid
      ) ?? [];

    unreadMessages.forEach((message) => {
      setProcessedMessages((messages) => messages.concat(message.id));
      updateDoc(
        doc(db, "conversations", conversationId, "messages", message.id),
        {
          read: true,
        }
      );
    });
  }, [conversationId, user.uid, messages]);

  const handleSendMessage = async (content) => {
    const message = {
      id: uuidv4(),
      date: new Date(),
      message: content,
      sentBy: user.uid,
      read: false,
    };

    await setDoc(
      doc(db, "conversations", conversationId, "messages", message.id),
      message
    );

    await updateDoc(doc(db, "conversations", conversationId), {
      lastMessageAt: message.date,
      lastMessage: message.message,
    });
  };

  if (loadingMessages) {
    return <DotLoader color="#ffffff" size={20} />;
  }

  const renderedMessages = [];
  let lastDate = null;

  messages
    .sort((a, b) => a.date.toDate() - b.date.toDate())
    .forEach((message) => {
      const currentDate =
        message.date instanceof Timestamp
          ? message.date.toDate()
          : new Date(message.date);

      if (!lastDate || currentDate.getDate() !== lastDate.getDate()) {
        // Add MessageSeparator if the date is different from the previous one
        renderedMessages.push(
          <MessageSeparator
            key={`separator-${currentDate.toDateString()}`}
            content={currentDate.toDateString()}
          />
        );
        lastDate = currentDate;
      }
      if (message.id === processedMessages[0]) {
        renderedMessages.push(
          <MessageSeparator
            key={`separator-${currentDate.toDateString()}`}
            content={"New messages"}
          />
        );
      }
      let messageDisplayed = removeTrailingNbsp(message.message);

      renderedMessages.push(
        <div key={message.id}>
          <Message
            key={message.id}
            style={{
              textAlign: "right",
            }}
            model={{
              message: messageDisplayed,
              sentTime: message.date.toDate().getTime().toString(),
              direction: message.sentBy === user.uid ? "outgoing" : "incoming",
              position: "single",
            }}
          />
          <Message.Footer
            style={{
              width: "auto",
              textAlign: "center",
              display: "flex",
              justifyContent: message.sentBy === user.uid ? "end" : "start",
            }}
            model={{
              direction: message.sentBy === user.uid ? "outgoing" : "incoming",
              position: "single",
            }}
          >
            {messageDateDisplay(message.date.toDate())}
          </Message.Footer>
        </div>
      );
    });

  return (
    <MainContainer
      className="w-100"
      style={{
        background: "transparent",

        // minHeight: "-webkit-fill-available",
        // paddingBottom: "env(safe-area-inset-bottom)",
      }}
    >
      <ChatContainer style={{ background: "transparent" }}>
        <MessageList
          autoScrollToBottom={true}
          disableOnYReachWhenNoScroll={true}
          style={{ background: "transparent" }}
        >
          {messages.length !== 0 ? (
            <>{renderedMessages}</>
          ) : (
            <div className="flex flex-col space-between h-[100%]">
              <div className="flex items-center justify-start mt-20 flex-column h-[50%] w-full">
                <div className="flex items-center justify-center flex-column">
                  <img src={IMAGES.JL_WHITE} className="h-7" alt="" />
                  <p className="w-80 text-center mt-1 text-white">
                    Start conversation
                  </p>
                </div>
              </div>
              <div
                className="flex justify-end items-center w-full px-3 text-sx gap-y-2 h-[50%]"
                style={{ flexWrap: "wrap", alignItems: "self-end" }}
              >
                <div className={styles.starterMessagesContainer}>
                  {starterMessages[user.isEmployer ? "firm" : "candidate"].map(
                    (message) => {
                      const parsedMessage = message.message
                        .replace(
                          "#name#",
                          conversation.incognito ? "" : conversationPartner.name
                        )
                        .replace(" ,", ",");

                      const cleanMessage = DOMPurify.sanitize(parsedMessage);
                      return (
                        <div
                          className={styles.starterMessage}
                          onClick={() => handleSendMessage(cleanMessage)}
                        >
                          <div className={styles.starterMessageTitle}>
                            {message.title}
                          </div>
                          <div>{parsedMessage}</div>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
          )}
        </MessageList>
        <MessageInput
          attachButton={false}
          placeholder="Type message here"
          onSend={handleSendMessage}
          style={{ maxWidth: "100%" }}
        />
      </ChatContainer>
    </MainContainer>
  );
};

export default Messages;
