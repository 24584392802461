import { Button, Form, Modal } from "react-bootstrap";
import { DotLoader } from "react-spinners";
import useMyJobs from "../../hooks/jobs/useMyJobs";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const WhichJobModal = ({ children, candidate, show, onClose, onJobSelect }) => {
  const { myJobs, isMyJobsLoading } = useMyJobs();

  const handleChooseJob = (job) => {
    onJobSelect(job);
    onClose();
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Choose a job</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isMyJobsLoading ? (
          <DotLoader color="#868bff" />
        ) : myJobs.length === 0 ? (
          <>You'll need to create a job first!</>
        ) : (
          <>
            <p>{children}</p>
            <Form className="py-4">
              <Form.Group>
                <div className="flex flex-wrap flex-col gap-2">
                  {myJobs.map((job, i) => (
                    <OverlayTrigger
                      key={i}
                      className="fixed"
                      placement="top"
                      overlay={
                        job.jobStatus !== "active" ? (
                          <Tooltip
                            placement="top"
                            className="in fixed"
                            id="tooltip-right"
                          >
                            The job is currently not active
                          </Tooltip>
                        ) : (
                          <></>
                        )
                      }
                    >
                      <div className="w-full">
                        <Button
                          disabled={job.jobStatus !== "active"}
                          key={job.uid}
                          onClick={() => handleChooseJob(job)}
                          variant="outlined"
                          className={`text-black border-2 border-black w-full `}
                        >
                          {candidate?.saved.includes(job.uid)
                            ? `Candidate is liked for the ${job.jobTitle}`
                            : `${job.jobTitle}`}
                        </Button>
                      </div>
                    </OverlayTrigger>
                  ))}
                </div>
              </Form.Group>
            </Form>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onClose}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WhichJobModal;
