import { useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import { BiMessage } from "react-icons/bi";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineMenu } from "react-icons/md";
import { PiCaretCircleLeftFill, PiSuitcaseLight } from "react-icons/pi";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import EditJobModal from "../../components/editJobModal/EditJobModal.jsx";
import SanitizeString from "../../components/SanitizeString";
import { IMAGES } from "../../constants/images.jsx";
import useMyJob from "../../hooks/jobs/useMyJob";
import NavbarRec from "../../utils/NavBarRec";
import styles from "./JobOverview.module.css";

const JobOverview = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const { id } = useParams();
  const { myJob, isMyJobLoading } = useMyJob(id);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <>
        <div
          className="flex flex-column items-center h-screen text-white"
          style={{
            height: "calc(100vh - 62px)", // Adjust the height based on your navbar height
          }}
        >
          <div className="flex justify-between items-center mt-5 mb-20 mx-3 w-[90%] lg:w-[80%]">
            <div
              className="flex items-center cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <PiCaretCircleLeftFill
                size={38}
                className="mr-2 text-[#46D6EA] duration-75 hover:scale-125"
              />
              Back
            </div>
            <div className="text-white top-5 right-4">
              <AiFillEdit
                size={24}
                className="cursor-pointer"
                onClick={handleShow}
              />
            </div>
          </div>
          {!isMyJobLoading && (
            <div className="flex flex-col lg:flex-row mt-2 mx-3 w-[90%] lg:w-[80%] gap-x-5 ">
              {/* left */}
              <div className="w-full lg:w-[100%] bg-white rounded-md text-[#131F2E] h-fit m-0 lg:mr-2 mb-3 pb-10 ">
                {/* background image */}
                <div className="relative">
                  {/* firm logo */}
                  <div className="flex justify-between w-full px-4 md:px-5 relative ">
                    <div className="flex justify-center md:justify-start w-[85%] absolute -bottom-10">
                      <img
                        className={styles.logo}
                        src={
                          myJob?.firmLogo == null
                            ? IMAGES.FIRM_LOGO
                            : myJob.firmLogo
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* job info */}
                <div className="px-4 md:px-5 py-2 mt-[10px] md:mt-[50px]">
                  <div className="flex justify-between items-center">
                    <div className="mt-1">
                      <p className="font-bold text-[22px]">
                        {myJob?.jobTitle}
                        {/* Job title */}
                      </p>
                      <p className="text-[15px] text-[#667588] my-2">
                        Created {new Date(myJob.createdAt).toDateString()}
                      </p>
                    </div>
                  </div>
                  {/* job more info */}
                  <div className="flex flex-column w-full py-3 text-[15px] font-medium">
                    <p className="flex items-center">
                      <CiLocationOn className="mr-2" />
                      {myJob?.jobCities == "" || myJob?.jobCities == null ? (
                        <>Location not disclosed</>
                      ) : (
                        <>{myJob?.jobCities.join(", ")}</>
                      )}
                    </p>
                    <p className="flex items-center">
                      <MdOutlineMenu className="mr-2" />
                      {myJob?.jobExpertise == "" ||
                      myJob?.jobExpertise == null ? (
                        <>No expertise specified</>
                      ) : (
                        <>{myJob?.jobExpertise?.join(", ")}</>
                      )}
                    </p>
                    <p className="flex items-center">
                      <PiSuitcaseLight className="mr-2" />
                      <>
                        {myJob?.isStudent ? (
                          <>Internship or student job</>
                        ) : (
                          <>
                            {myJob?.jobExperienceMin} -{" "}
                            {myJob?.jobExperienceMax} years of experience
                          </>
                        )}
                      </>
                    </p>

                    <p className="flex items-center">
                      <BiMessage className="mr-2" />
                      {myJob?.jobLanguages == "" ||
                      myJob?.jobLanguages == null ? (
                        <>No language provided</>
                      ) : (
                        <>{myJob?.jobLanguages.join(", ")}</>
                      )}
                    </p>
                  </div>
                </div>
                {/* Job Description */}
                <div className="px-4 md:px-5 py-2 border-t-[0.5px] border-gray-200 my-2">
                  <h4 className="text-[#131F2E]/50 uppercase my-4 font-bold">
                    full description
                  </h4>
                  <SanitizeString
                    className="list-disc"
                    content={myJob?.jobDescription ?? ""}
                  />
                </div>
                {/* Job Requirement */}
                <div className="px-4 md:px-5 py-2 border-t-[0.5px] border-gray-200 my-2 sm:px-4 md:px-5">
                  <h4 className="text-[#131F2E]/50 uppercase my-4 font-bold">
                    REQUIREMENTS
                  </h4>
                  <div className="text-justify">
                    <ul>
                      <li className="py-2">
                        <strong>Expertise: &nbsp;</strong>
                        {myJob?.jobExpertise?.join(", ")}
                      </li>
                      <li className="py-2">
                        <strong>Skills: &nbsp;</strong>
                        {myJob?.jobSkills?.join(", ")}
                      </li>
                      <li className="py-2">
                        <strong>Minimum experience: &nbsp;</strong>
                        {myJob?.jobExperienceMin}
                      </li>
                      <li className="py-2">
                        <strong>Maximum experience: &nbsp;</strong>
                        {myJob?.jobExperienceMax}
                      </li>
                      <li className="py-2">
                        <strong>Required languages: &nbsp;</strong>
                        {myJob?.jobLanguages?.join(", ")}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
      {!isMyJobLoading && (
        <EditJobModal
          showModal={showModal}
          handleClose={handleClose}
          job={myJob}
        />
      )}
    </>
  );
};

export default JobOverview;
